import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, Typography, Button, Container, Skeleton, } from '@mui/material';
import { styled } from '@mui/system';
import { Add } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../../../action/catgoryAction";
import { fetchSubCetegory } from '../../../../action/subCategory';
import { useLocation, useNavigate,useParams } from 'react-router-dom';
import {ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllService } from '../../../../action/serviceAction';
import CartGrid from '../../inc/CartItem';
import { addToCart, getAllCart } from '../../../../action/cartAction';
import OtherCat from '../../inc/otherCat';
import { CLEAR_CART } from '../../../../constant/cartConstant';
import Footer from '../../inc/Footer/footer';
import './Services.css'
import LoginModal from '../../inc/LoginCheck'

const MainLayout = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAllCategory, loading: categoryLoading } = useSelector((state) => state.getAllCategory);
  const { getServiceData, loading: serviceLoading } = useSelector((state) => state.getServiceData);
  const { fetchAllSubCategory } = useSelector((state) => state.fetchAllSubCategory);
  const [categoryData, setCategoryData] = useState(getAllCategory);
  const [selectedCategoryId, setSelectedCategoryId] = useState(id);
  const [serviceData, setServiceData] = useState([])
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");
  const cartItem = useSelector(state => state.cart);
  const [showModal, setShowModal] = useState(false);
  const { user, isAuthenticated, loading } = useSelector((state) => state.user);
  console.log(cartItem?.cart?.services,"ghghghghghgfhgf")
  const isInCart = (itemId) => {

    return cartItem?.cart?.services?.some(item => item?.serviceId?._id === itemId?._id);
  };
  useEffect(() => {
    dispatch(getCategory());
    
    if (selectedCategoryId) {
      dispatch(getAllService());
      dispatch(fetchSubCetegory(selectedCategoryId));
    } 
  }, [dispatch,selectedCategoryId]);
  useEffect(() => {
    if (selectedCategoryId!= cartItem[0]?.categoryId) {
      dispatch({
        type: CLEAR_CART,
    });
    }
    setSelectedCategoryId(id)
    const filteredData = functFilter(id, getServiceData?.services);
      setServiceData(filteredData);
      
  }, [selectedCategoryId,id,getServiceData]);

  useEffect(() => {
    if (selectedSubCategoryId) {
      const filteredData = getServiceData?.services.filter((service) => service.subcategoryId === selectedSubCategoryId);
      setServiceData(filteredData);
    }
  }, [selectedSubCategoryId]);
const functDataAll=()=>{
  const filteredData = getServiceData?.services?.filter((service) => service.categoryId === selectedCategoryId);
  setServiceData(filteredData);
  setSelectedSubCategoryId("")
}
  const functFilter = (subcategoryId, services) => {
    return services?.filter((service) => service.categoryId === subcategoryId);
  };
  useEffect(() => {
    if (getAllCategory) setCategoryData(getAllCategory);
  }, [getAllCategory]);

  const categoryCount = categoryData?.categories?.length || 0;
  const subCategoryCount = serviceData?.length || 0;

  const handleBookService = (service) => {
    const isAuthenticated = localStorage.getItem("user"); 
    if (!isAuthenticated) {
      setShowModal(true); 
    } else {
      dispatch(addToCart(service, user?._id));
      setTimeout(() => {
        dispatch(getAllCart(user._id));
      }, 100);
    }
    
  };

 
const navFunctServicDetail=(value)=>{
  const titleName = value.name.replace(/\s+/g, '-').toLowerCase(); // Replace spaces with hyphens for URL-friendly format
  navigate(`/serviceDetail/${titleName}`, { state: { serviceData: value } });
}
  return (
    <>
     <Container maxWidth={false} sx={{ py: 1, px: { xs: 2, sm: 4, md: 6, lg: 8 }, mt:14  }}>
      {/* <ToastContainer position="top-right" autoClose={3000} /> */}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3}>
          <Box>
            <StyledCard sx={{ p: 3, mb: 3}}>
              <Box sx={{ position: 'relative', mb: 4, textAlign: 'left' }}>
                {categoryLoading ? (
                  <Skeleton variant="text" width="60%" height={40} sx={{ mx: 'auto' }} />
                ) : (
                  <Typography variant="h6" sx={{ fontWeight: 500,justifyContent:"space-between", color: '#000DFF', display: 'flex', position: 'relative' }}>
                    <span style={{color:"#000DFF", fontSize:"18px"}}>SERVICES</span> 
                    <span onClick={functDataAll} style={{fontWeight:700,color:"#000", fontSize:"13px"}}>View All</span>
                  </Typography>
                )}
                <Box sx={{ position: 'absolute', bottom: '-10px', left: '50%', transform: 'translateX(-50%)', width: '100%', height: '1px', background: 'linear-gradient(90deg, transparent, #000DFF, transparent)' }} />
              </Box>
              <Grid container spacing={2} justifyContent="center">
                {categoryLoading
                  ? Array.from(new Array(categoryCount || 6)).map((_, index) => (
                    <Grid item xs={6} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <StyledCard sx={{ p: 1, width: '100%', maxWidth: '120px', height: 100 }}>
                        <Skeleton variant="circular" width={40} height={40} sx={{ mx: 'auto', mb: 1 }} />
                        <Skeleton variant="text" width="80%" sx={{ mx: 'auto' }} />
                      </StyledCard>
                    </Grid>
                  ))
                  : fetchAllSubCategory?.getsubcategory?.map((service) => (
                    <Grid item xs={12} key={service._id} sx={{ display: 'flex', }}>
                      <StyledCard
                        onClick={() => setSelectedSubCategoryId(service._id)}
                        sx={{
                          p: 1,
                          cursor: 'pointer',
                          width: '100%',
                          maxWidth: '100%',
                          alignItems: 'center',
                          display:"block",
                          backgroundColor: selectedSubCategoryId === service._id ? 'rgb(0 13 255)' : 'transparent',
                          transition: 'background-color 0.3s ease',
                        }}
                      >
                       {/* <img width="80" height="100" src={service.image?.url} /> */}
                        <Typography className='truncate' variant="body2" sx={{ fontWeight: 400, color: selectedSubCategoryId === service._id ? '#fff' : 'grey' }}>{service.SubcategoryTitle}</Typography>
                      </StyledCard>
                    </Grid>
                  ))}
              </Grid>
            </StyledCard>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
      
          <Grid container spacing={1}> 
            {serviceLoading
              ? Array.from(new Array(subCategoryCount || 5)).map((_, index) => (
                <Grid item xs={12} key={index}>
                  <StyledCard sx={{ p: 1.5, display: 'flex', alignItems: 'center' }}>
                    <Skeleton variant="rectangular" width={70} height={70} sx={{ borderRadius: 2, mr: 4 }} />
                    <Box sx={{ flexGrow: 1 }}>
                      <Skeleton variant="text" width="60%" />
                      <Skeleton variant="text" width="40%" />
                      <Skeleton variant="text" width="30%" />
                    </Box>
                    <Skeleton variant="rectangular" width={80} height={36} sx={{ borderRadius: 1 }} />
                  </StyledCard>
                </Grid>
              ))
              : serviceData?.map((subCategory) => (
                <Grid item xs={12} key={subCategory._id}>
                  <StyledCard sx={{ p: 1.5, display: 'flex', alignItems: 'center' }}>
                    <Box component="img" sx={{ width: 70, height: 70, borderRadius: 2, mr: 4 }} src={subCategory.image.url} alt='img' />
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography title={subCategory.name} onClick={()=>navFunctServicDetail(subCategory)} variant="subtitle2" fontWeight="bold" sx={{ mb: 0.5, color: '#000DFF' }}> {subCategory.name.length > 50 ? `${subCategory.name.slice(0, 50)}...` : subCategory.name}</Typography>                      
                      <Typography variant="body2" fontWeight="bold" sx={{ mb: 0.5 }}>
                        
                        <div className="price-container">
      <p className="original-price">₹{subCategory.price.basePrice.toLocaleString()}</p>
      <p className="discount-price">₹{subCategory.price.totalPrice.toLocaleString()}</p>
      <p className="discount-percent">{subCategory.price.discount}% off</p>
    </div>
                        {/* {subCategory.discount && (
                          <Typography component="span" variant="caption" color="error" sx={{ ml: 1 }}>
                            <LocalOffer sx={{ fontSize: 10, verticalAlign: 'text-top' }} />
                            {subCategory.discount}% off
                          </Typography>
                        )} */}
                      </Typography>
                      {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Rating name="read-only" value={subCategory.rating} readOnly size="small" precision={0.1} sx={{ fontSize: '0.7rem' }} />
                        <Typography variant="caption" color="text.secondary" sx={{ ml: 0.5 }}>({subCategory.reviews})</Typography>
                      </Box> */}
                    </Box>
                    {isInCart(subCategory) ? (<PremiumButton
          size="small"
          startIcon={ <Add />}
          disabled
        >Added
        </PremiumButton>) : (<PremiumButton
        size="small"
        onClick={() => handleBookService(subCategory)}
      >
        { 'Book Now'}
      </PremiumButton>)}
                    
                  </StyledCard>
                </Grid>
              ))}
              <LoginModal show={showModal} onClose={() => setShowModal(false)} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box>
            <CartGrid  />
           <OtherCat />
          </Box>
        </Grid>
      </Grid>
    </Container>
    <Footer />
    <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
    </>
   
  );
}

export default MainLayout;

const StyledCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.25)',
  borderRadius: '12px',
  backdropFilter: 'blur(5px)',
  boxShadow: '0 8px 25px rgba(0, 13, 255, 0.1)',
  border: '4px solid white',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-3px)',
    boxShadow: '0 8px 25px rgba(0, 13, 255, 0.1)'
  },
}));

const PremiumButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(135deg, #000DFF 0%, #6B73FF 100%)',
  color: '#FFFFFF',
  fontWeight: 600,
  borderRadius: 6,
  padding: '6px 16px',
  border: '3px solid white',
  boxShadow: '0 8px 25px rgba(0, 13, 255, 0.1)',
  fontSize: '0.8rem',
  textTransform: 'none',
  '&:hover': {
    background: 'linear-gradient(135deg, #0009CC 0%, #5158CC 100%)'
  },
  '&:disabled': {
    background: '#CCCCCC',
    color: '#666666',
  },
}));

const IconWrapper = styled(motion.div)(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: '10%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)',
  marginBottom: theme.spacing(1),
}));