import axios from "axios";
import { BLOG_FAIL, BLOG_REQUEST, BLOG_SUCCESS, DELETE_BLOG_FAIL, DELETE_BLOG_REQUEST, DELETE_BLOG_SUCCESS, GET_BLOG_FAIL, GET_BLOG_REQUEST, GET_BLOG_SUCCESS } from "../constant/blogConstant";
import siteConfig from "../siteConfig";

export const blogCreate = (BlogData) => async (dispatch) => {
    try {
      dispatch({ type: BLOG_REQUEST });
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: localStorage.getItem('token'),
        },
      };
      const { data } = await axios.post(
        `${siteConfig.base_URL}/api/v1/admin/BlogPost/new`,
        BlogData,
        config
      );
      dispatch({ type: BLOG_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: BLOG_FAIL, payload: error.response.data.message || error.message });
    }
  };
  

  export const getAllBlogs = () => async(dispatch) =>{
    try {
      dispatch({type:GET_BLOG_REQUEST})
      const config = {
        headers:{
          'Content-Type': 'multipart/form-data',
        }
      }
      const {data} = await axios.get( `${siteConfig.base_URL}/api/v1/AllBlogs`,config)
      dispatch({
        type:GET_BLOG_SUCCESS ,payload:data
      })
    } catch (error) {
      dispatch({
        type:GET_BLOG_FAIL
      })
    }
  }


  export const deleteBlogs = (_id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_BLOG_REQUEST
        })
        const token = localStorage.getItem('token')
        const config = {
            headers: {
                'content-type': 'application/json',
                token: token
            }
        }
        const { data } = await axios.delete(
            `${siteConfig.base_URL}/api/v1//blog/${_id}`,
            config
        )
        dispatch({
            type: DELETE_BLOG_SUCCESS, payload: data
        })
    } catch (error) {
        dispatch({
            type: DELETE_BLOG_FAIL, payload: error.message
        })
    }
}