import React, { useState, useEffect } from 'react';
import {  Grid,Box, Card, Typography, Button, Badge, CardContent,
    InputLabel,
    Stack,
    TextField } from "@mui/material";
import { useParams, Link } from 'react-router-dom';
import { styled } from '@mui/system';
import 'react-toastify/dist/ReactToastify.css';
import ProfileSideBar from '../../inc/profileSideBar';
import Footer from '../../inc/Footer/footer';
import { useDispatch, useSelector } from "react-redux";
import { updateUser, user_Detail } from '../../../../action/UserAuthActions';

const PremiumButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(135deg, #000DFF 0%, #6B73FF 100%)',
    color: '#FFFFFF',
    fontWeight: 600,
    borderRadius: 6,
    padding: '6px 16px',
    border: '3px solid white',
    boxShadow: '0 8px 25px rgba(0, 13, 255, 0.1)',
    fontSize: '0.8rem',
    textTransform: 'none',
    '&:hover': {
      background: 'linear-gradient(135deg, #0009CC 0%, #5158CC 100%)'
    },
    '&:disabled': {
      background: '#CCCCCC',
      color: '#666666',
    },
  }));
const Profile=()=> {
    const [userDetail, setUserDetail] = useState({
        name: "",
        _id: "",
        dob: "",
        mobileNo: "",
    });
    const dispatch = useDispatch();

    // Get user data from Redux store
    const { user, loading, error } = useSelector((state) => state.user);
  console.log(user,"fffff")
    useEffect(() => {
      dispatch(user_Detail()); // Fetch user details
    }, [dispatch]);
    useEffect(() => {
        if (user && Object.keys(user).length > 0) {
          setUserDetail({
            name: user.name || "",
            _id: user._id || "",
            email: user.email || "",
            mobileNo: user.mobileNo || "",
            dob:user.mobileNo || ""
          });
        }
      }, [user]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "mobileNo" && !/^\d*$/.test(value)) return;
        setUserDetail((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const onSubmit = () => {
        dispatch(updateUser(userDetail));
      };
  return (
   <>
    <Box sx={{ marginTop:"110px", marginLeft:"50px", marginRight:"50px" }}>
      <Grid container spacing={4}>
      <Grid item xs={12} sm={3}>
      <ProfileSideBar />
        </Grid>
        <Grid item xs={12} sm={9}>
                    <StyledCard sx={{ mb: 3, overflow: 'hidden', position: 'relative' }}>
                        <Box sx={{padding:"40px"}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} style={{ marginBottom: "20px", marginTop:"-25px" }}>
                                <Box sx={{ position: 'relative', mb: 1, mt: 2, textAlign: 'center' }}>
                <Typography variant="h5" sx={{ fontWeight: 500, color: '#000DFF', display: 'inline-block', position: 'relative' }}>
                  My Profile
                </Typography>
                <Box sx={{ position: 'absolute', bottom: '-10px', left: '50%', transform: 'translateX(-50%)', width: '30%', height: '1px', background: 'linear-gradient(90deg, transparent, #000DFF, transparent)' }} />
              </Box>
                                </Grid>
                                <Grid item xs={6} style={{ marginBottom: "5px" }}>
                                    <Stack spacing={1}>
                                        <InputLabel
                                            htmlFor="Name"
                                            style={{ marginBottom: "5px", fontSize: "12px", color: "#422afb" }}
                                        >
                                            Name<span style={{ color: "#f44336" }}>*</span>
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            placeholder="Name"
                                            id="name"
                                            name="name"
                                            variant="outlined"
                                            value={userDetail.name}
                                            onChange={handleChange}
                                            InputProps={{
                                                style: {
                                                    borderColor: "#422afb",
                                                    padding: "10px 0px",
                                                    fontSize: '12px'
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: "12px" },
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={6} style={{ marginBottom: "5px" }}>
                                    <Stack spacing={1}>
                                        <InputLabel
                                            htmlFor="Email"
                                            style={{ marginBottom: "5px", fontSize: "12px", color: "#422afb" }}
                                        >
                                            Email<span style={{ color: "#f44336" }}>*</span>
                                        </InputLabel>
                                        <TextField
                                            disabled
                                            fullWidth
                                            size="small"
                                            placeholder="Email"
                                            id="email"
                                            name="email"
                                            variant="outlined"
                                            value={userDetail.email}
                                            onChange={handleChange}
                                            InputProps={{
                                                style: {
                                                    borderColor: "#422afb",
                                                    padding: "10px 0px",
                                                    fontSize: '12px'
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: "12px" },
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack spacing={1}>
                                        <InputLabel
                                            htmlFor="Date"
                                            style={{ marginBottom: "5px", fontSize: "12px", color: "#422afb" }}
                                        >
                                            Date<span style={{ color: "#f44336" }}>*</span>
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            placeholder="Date"
                                            id="categoryTitle"
                                            name="dob"
                                            type="date"
                                            variant="outlined"
                                            value={userDetail.dob}
                                            onChange={handleChange}
                                            InputProps={{
                                                style: {
                                                    borderColor: "#422afb",
                                                    padding: "10px 0px",
                                                    fontSize: '12px'
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: "12px" },
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                               
                                <Grid item xs={6} style={{ marginBottom: "5px" }}>
                                    <Stack spacing={1}>
                                        <InputLabel
                                            htmlFor="MobileNo"
                                            style={{ marginBottom: "5px", fontSize: "12px", color: "#422afb" }}
                                        >
                                            Mobile No<span style={{ color: "#f44336" }}>*</span>
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            placeholder="MobileNo"
                                            id="MobileNo"
                                            name="mobileNo"
                                            variant="outlined"
                                            value={userDetail.mobileNo}
                                            onChange={handleChange}
                                            InputProps={{
                                                style: {
                                                    borderColor: "#422afb",
                                                    padding: "10px 0px",
                                                    fontSize: '12px'
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: "12px" },
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                                <PremiumButton onClick={onSubmit} size="small" sx={{ mt: 1 }}>submit</PremiumButton>
                            </Grid>

                        </Box>
                        </StyledCard>
        </Grid>
       </Grid>
    </Box>
   <Footer />
   </>
  )
}
const StyledCard = styled(Card)(({ theme }) => ({
    background: 'rgb(242, 242, 242)',
    borderRadius: '12px',
    // backdropFilter: 'blur(5px)',
    // boxShadow: '0 8px 25px rgba(0, 13, 255, 0.1)',
    border: '4px solid white',
}));
export default Profile
