import React, { useState } from 'react';
import {
    Container, Typography, CardContent, Box, IconButton, Grid, Chip, Modal, Backdrop, Fade, Avatar, List, ListItem, ListItemText, Button
} from '@mui/material';
import { ArrowBack, Close, AccessTime, CalendarToday, LocationOn, Spa } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import SortIcon from '@mui/icons-material/Sort';
import MobileBottomNavigation from '../../mobile/mobilebottomnav/bottomnav';

const ServiceBookingHistory = () => {
    const [open, setOpen] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [sortModalOpen, setSortModalOpen] = useState(false);
    const [sortMode, setSortMode] = useState('latest');

    const handleOpen = (booking) => {
        setSelectedBooking(booking);
        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    const handleOpenSortModal = () => setSortModalOpen(true);
    const handleCloseSortModal = () => setSortModalOpen(false);
    const handleSortModeChange = (mode) => {
        setSortMode(mode);
        handleCloseSortModal();
    };

    const extractPrice = (priceString) => parseFloat(priceString.replace(/[^\d.]/g, ''));

    const sortedBookings = [...bookings].sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        switch (sortMode) {
            case 'latest': return dateB - dateA;
            case 'oldest': return dateA - dateB;
            case 'priceHighToLow': return extractPrice(b.price) - extractPrice(a.price);
            case 'priceLowToHigh': return extractPrice(a.price) - extractPrice(b.price);
            case 'alphabetical': return a.parlourName.localeCompare(b.parlourName);
            default: return dateB - dateA;
        }
    });

    return (
        <React.Fragment>
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', bgcolor: '#fafafa', minHeight: '100vh' }}>
                <Box sx={{
                    display: 'flex', alignItems: 'center', p: 2, bgcolor: '#ffffff8a', backdropFilter: 'blur(2px)',
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)', position: 'sticky', top: 0, zIndex: 10
                }}>
                    <IconButton component={Link} to="/" edge="start" sx={{ mr: 1, color: 'black' }}>
                        <ArrowBack style={{ fontSize: '1.1rem', fontWeight: 400 }} />
                    </IconButton>
                    <Typography sx={{ fontSize: '0.9rem', fontWeight: 500, background: 'black', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                        My Bookings
                    </Typography>
                    <Button size="small" onClick={handleOpenSortModal} sx={{ ml: 'auto', color: 'black', textTransform: 'none', fontWeight: 500 }}
                        startIcon={<SortIcon style={{ fontSize: '1.1rem', fontWeight: 400 }} />}>
                        Sort By
                    </Button>
                </Box>
                <Container maxWidth="lg" sx={{ flexGrow: 1, py: 3, maxWidth: '800px !important', mb: 10 }} >
                    <Grid container spacing={1}>
                        {sortedBookings.map((booking) => (
                            <Grid item xs={12} sm={6} key={booking.id} >
                                <Box onClick={() => handleOpen(booking)} sx={webStyle.StyledBox}>
                                    <CardContent sx={{ p: 2.5 }}>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                            <Box>
                                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                                                    {booking.parlourName}
                                                </Typography>
                                                <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
                                                    {booking.service}
                                                </Typography>
                                            </Box>
                                            <StatusChip label={booking.status} status={booking.status} />
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" mt={1}>
                                            <Typography variant="caption" sx={{ fontSize: '0.7rem' }}>{booking.date}</Typography>
                                            <Typography variant="caption" sx={{ fontSize: '0.7rem' }}>{booking.time}</Typography>
                                        </Box>
                                        <Box mt={1} pt={1} borderTop="1px solid #E0E0E0" display="flex" justifyContent="space-between" alignItems="center">
                                            <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.7rem' }}>Total Price</Typography>
                                            <Typography variant="subtitle2" fontWeight={600} sx={{ fontSize: '0.8rem' }}>${booking.price}</Typography>
                                        </Box>
                                    </CardContent>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
            <Modal open={open} onClose={handleClose} closeAfterTransition BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500, style: { backdropFilter: 'blur(4px)' } }}>
                <Fade in={open}>
                    <ModalContent>
                        {selectedBooking && (
                            <>
                                <Box sx={{ bgcolor: 'black', p: 3, position: 'relative' }}>
                                    <IconButton onClick={handleClose} sx={{
                                        position: 'absolute', right: 8, top: 8, color: 'black', background: 'white',
                                        '&:hover': { transform: 'translateY(-2px)', cursor: 'pointer', background: "white" },
                                    }}>
                                        <Close style={{ fontSize: '12px' }} />
                                    </IconButton>
                                    <Box display="flex" alignItems="center" mb={2}>
                                        <Avatar sx={{ bgcolor: 'white', mr: 2, color: 'black', backdropFilter: "5px" }}>
                                            <Spa />
                                        </Avatar>
                                        <Box>
                                            <Typography sx={webStyle.AnimatedTypography} variant="h6" fontWeight="bold" color="white">
                                                {selectedBooking.parlourName}
                                            </Typography>
                                            <Typography variant="body2" color="white" sx={webStyle.AnimatedTypography}>
                                                {selectedBooking.service}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <StatusChip label={selectedBooking.status} status={selectedBooking.status} />
                                </Box>
                                <Box sx={{ p: 3 }}>
                                    <Typography variant="body1" mb={1} display="flex" alignItems="center" style={{ fontSize: "0.7rem" }} sx={webStyle.AnimatedTypography}>
                                        <CalendarToday fontSize="small" sx={{ mr: 1, color: 'text.secondary', fontSize: "0.7rem" }} />
                                        {selectedBooking.date}
                                    </Typography>
                                    <Typography variant="body1" mb={1} display="flex" alignItems="center" style={{ fontSize: "0.7rem" }} sx={webStyle.AnimatedTypography}>
                                        <AccessTime fontSize="small" sx={{ mr: 1, color: 'text.secondary', fontSize: "0.7rem" }} />
                                        {selectedBooking.time}
                                    </Typography>
                                    <Typography variant="body1" mb={1} display="flex" alignItems="center" style={{ fontSize: "0.7rem" }} sx={webStyle.AnimatedTypography}>
                                        <LocationOn fontSize="small" sx={{ mr: 1, color: 'text.secondary', fontSize: "0.7rem" }} />
                                        {selectedBooking.location}
                                    </Typography>
                                    <Box mt={2} pt={2} borderTop="1px solid #E0E0E0">
                                        <Typography variant="body2" color="black" sx={webStyle.AnimatedTypography}>
                                            Booking ID: {selectedBooking.bookingId}
                                        </Typography>
                                        <Typography variant="h6" fontWeight="bold" mt={1} sx={webStyle.AnimatedTypography}>
                                            Total: ${selectedBooking.price}
                                        </Typography>
                                    </Box>
                                </Box>
                            </>
                        )}
                    </ModalContent>
                </Fade>
            </Modal>
            <Modal open={sortModalOpen} onClose={handleCloseSortModal} closeAfterTransition BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500, style: { backdropFilter: 'blur(4px)' } }}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{
                    bgcolor: 'white', boxShadow: 24, p: 3, minWidth: 200, borderRadius: 4,
                    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    '&:hover': { transform: 'translateY(-5px)', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)' }, zIndex: 1301
                }}>
                    <List>
                        <ListItem button onClick={() => handleSortModeChange('latest')}>
                            <ListItemText primary="Latest" style={{ fontSize: '0.7rem' }} />
                        </ListItem>
                        <ListItem button onClick={() => handleSortModeChange('priceHighToLow')}>
                            <ListItemText primary="Price: High to Low" />
                        </ListItem>
                        <ListItem button onClick={() => handleSortModeChange('priceLowToHigh')}>
                            <ListItemText primary="Price: Low to High" />
                        </ListItem>
                    </List>
                </Box>
            </Modal>
            <MobileBottomNavigation />
        </React.Fragment>
    );
};

export default ServiceBookingHistory;

const webStyle = {
    StyledBox: {
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)', backdropFilter: "blur(1px)", backgroundColor: "rgba(255, 255, 255, 0.8)",
        border: 'none', borderRadius: 4, background: 'white', Padding: '5px', marginBottom: '2px',
        transition: 'transform 0.2s ease-in-out',
        '&:hover': { transform: 'translateY(-2px)', cursor: 'pointer' },
    },
    AnimatedTypography: {
        animation: '$fadeIn 0.5s ease-out',
        '@keyframes fadeIn': {
            '0%': { opacity: 0, transform: 'translateY(10px)' },
            '100%': { opacity: 1, transform: 'translateY(0)' },
        },
    }
};

const ModalContent = styled(Box)(({ theme }) => ({
    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '90%', maxWidth: 340,
    backgroundColor: theme.palette.background.paper, boxShadow: '0 4px 20px rgba(0,0,0,0.15)', borderRadius: 20, overflow: 'hidden',
    [theme.breakpoints.down('sm')]: { width: '95%', maxWidth: 320 },
}));

const StatusChip = styled(Chip)(({ status }) => ({
    borderRadius: 10, fontSize: '0.75rem', height: 28, fontWeight: 600,
    color: status === 'Confirmed' ? '#00695C' : '#B71C1C',
    backgroundColor: status === 'Confirmed' ? '#E0F2F1' : '#FFEBEE',
}));

const bookings = [
    { id: 2, service: 'Home Cleaning', date: '20 Jun 2024', time: '02:30 PM', status: 'Pending', price: '800', duration: '3 hours', provider: 'CleanHome Services' },
    { id: 3, service: 'Plumbing', date: '25 Jun 2024', time: '11:45 AM', status: 'Cancelled', price: '1120', duration: '2 hours', provider: 'FixIt Plumbers' },
];
