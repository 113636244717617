import {
    ALL_SERVICE_FAIL,
    ALL_SERVICE_REQUEST,
    ALL_SERVICE_SUCCESS,
    DELETE_SERVICE_FAIL,
    DELETE_SERVICE_REQUEST,
    DELETE_SERVICE_RESET,
    DELETE_SERVICE_SUCCESS,
    SERVICE_FAIL,
    SERVICE_REQUEST,
    SERVICE_RESET,
    SERVICE_SUCCESS,
    UPDATE_SERVICE_FAIL,
    UPDATE_SERVICE_REQUEST,
    UPDATE_SERVICE_RESET,
    UPDATE_SERVICE_SUCCESS,
    // CATEGORY_FAIL,
    // CATEGORY_REQUEST,
    // CATEGORY_RESET,
    // CATEGORY_SUCCESS,
    // DELETE_CATEGORY_FAIL,
    // DELETE_CATEGORY_REQUEST,
    // DELETE_CATEGORY_RESET,
    // DELETE_CATEGORY_SUCCESS,
    // UPDATE_CATEGORY_FAIL,
    // UPDATE_CATEGORY_REQUEST,
    // UPDATE_CATEGORY_RESET,
    // UPDATE_CATEGORY_SUCCESS,
  } from "../constant/serviceConstant";
  
  export const serviceReducer = (
    state = { service: {}, loading: false },
    action
  ) => {
    switch (action.type) {
      case SERVICE_REQUEST:
        return {
          ...state,
          loading: true,
          isAuthenticated: false,
        };
      case SERVICE_SUCCESS:
        return {
          ...state,
          loading: false,
          service: action.payload,
          isAuthenticated: true,
        };
      case SERVICE_RESET:
        return {
          ...state,
          loading: false,
          isAuthenticated: true,
        };
      case SERVICE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isAuthenticated: false,
          service: null,
        };
      default:
        return state;
    }
  };
  
  export const getServiceReducer = (state = { getServiceData: [] }, action) => {
    switch (action.type) {
      case ALL_SERVICE_REQUEST:
        return {
          loading: true,
          getServiceData: [],
        };
      case ALL_SERVICE_SUCCESS:
        return {
          loading: false,
          getServiceData: action.payload,
        };
      case ALL_SERVICE_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export const deleteServiceReducer = (state = { DeletedService: {} }, action) => {
    switch (action.type) {
      case DELETE_SERVICE_REQUEST:
        return {
          ...state,
          loading: true,
          isDeleted: false
        };
      case DELETE_SERVICE_SUCCESS:
        return {
          ...state,
          loading: false,
          isDeleted: true
        };
      case DELETE_SERVICE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      case DELETE_SERVICE_RESET:
        return {
          ...state,
          isDeleted: false
        };
      default:
        return state;
    }
  };
  
  export const updateServiceReducer = (state = { updateService: {}, loading: false }, action) => {
    switch (action.type) {
      case UPDATE_SERVICE_REQUEST:
        return {
          ...state,
          loading: true,
          isAuthenticated: false
        };
      case UPDATE_SERVICE_SUCCESS:
        return {
          ...state,
          loading: false,
          updateService: action.payload,
          isAuthenticated: true
        };
      case UPDATE_SERVICE_RESET:
        return {
          ...state,
          loading: false,
          isAuthenticated: true
        };
      case UPDATE_SERVICE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isAuthenticated: false,
          updateService: null
        };
      default:
        return state;
    }
  };
  