import React, { useRef } from 'react';
import { useDispatch } from "react-redux";
import { Button, Box, Typography, Container, ThemeProvider, createTheme, Paper } from '@mui/material';
import { styled } from '@mui/system';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { CreateLanding } from '../../action/landingBgAction';
import { motion } from 'framer-motion';

// const theme = createTheme({
//   palette: {
//     primary: { main: '#3a86ff' },
//     secondary: { main: '#ff006e' },
//     background: { default: '#f8f9fa' },
//   },
//   typography: {
//     fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
//   },
//   shape: { borderRadius: 8 },
// });

const UploadContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: '12px',
  padding: '20px',
  boxShadow: '0 4px 20px rgba(66, 42, 251, 0.08)',
  border: '1px solid rgba(66, 42, 251, 0.12)',
}));

const UploadArea = styled(motion.div)(({ theme }) => ({
  border: '2px dashed rgba(66, 42, 251, 0.2)',
  padding: '30px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  backgroundColor: 'rgba(66, 42, 251, 0.02)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 5,
  padding: theme.spacing(0.8, 3),
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '0.9rem',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.2s ease',
  '&:hover': {
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
  },
}));

const ImagePreview = styled(motion.div)(({ theme }) => ({
  width: '100%',
  height: 150,
  borderRadius: '10px',
  overflow: 'hidden',
  marginBottom: theme.spacing(2),
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
}));

const LandingHerobg = () => {
  const dispatch = useDispatch();
  const [heroimg, setheroImg] = React.useState({
    file: null
  })
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!heroimg.file) {
      return;
    }
    dispatch(CreateLanding(heroimg));
    setheroImg({
      file: null,
    });
  };
  const fileInputRef = React.useRef(null);

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file, "ffff")
    if (file) {
      setheroImg((prevState) => ({
        ...prevState,
        file: file,
      }));

    }
  };

  const handleRemoveImage = () => {
    setheroImg((prevState) => ({
      ...prevState,
      file: null,
    }));
  };

  return (

    <Container maxWidth="sm" sx={{ py: 3 }}>
      <form onSubmit={handleSubmit}>
        <UploadContainer elevation={0}>
          {heroimg.file ? (
            <>
              <ImagePreview>
                <img src={URL.createObjectURL(heroimg.file)} alt="Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              </ImagePreview>
              <Box display="flex" justifyContent="center">
                <StyledButton onClick={handleRemoveImage} variant="outlined" color="secondary" startIcon={<DeleteOutlineIcon />} size="small">
                  Remove
                </StyledButton>
              </Box>
            </>
          ) : (
            <UploadArea
              onClick={handleUploadButtonClick}
              whileHover={{ scale: 1.02, boxShadow: '0 4px 15px rgba(66, 42, 251, 0.15)' }}
              whileTap={{ scale: 0.98 }}
            >
              <input ref={fileInputRef} type="file" accept="image/*" style={{ display: "none" }} onChange={handleFileChange} />
              <CloudUploadIcon sx={{ fontSize: 40, mb: 1, color: "#422afb" }} />
              <Typography variant="body2" align="center" color="#422afb" fontWeight="700" style={{ fontSize: "0.7rem" }}>
                Click to Upload
              </Typography>
              <Typography variant="caption" align="center" style={{ fontSize: "0.7rem", color: "#333" }} mt={0.5}>
                JPG, PNG up to 10MB
              </Typography>
            </UploadArea>
          )}
        </UploadContainer>

        <Box display="flex" justifyContent="center" mt={2}>
          <StyledButton type="submit" style={{ backgroundColor: "#422afb" }} variant="contained" disabled={!heroimg.file} size="small">
            Upload
          </StyledButton>
        </Box>
      </form>
    </Container>

  );
};

export default LandingHerobg;