import { Box, Tab, Tabs, Typography } from "@mui/material";
import '../common/commonStyle.css'
import TopNav from "../sidebar/navbar";
import React, { useEffect, useState } from "react";
import Sidebar from "../sidebar/sidebar";
import FaqCreate from "./FaqCreate";
import FaqPrivews from "./FaqPreview";
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from "framer-motion";

const Faq = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const pageload = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(pageload);
  }, []);

  const handleChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <>
      <TopNav />
      <Box height={60} />
      {
        loading ?
          <div className="loading">
            <div className="loader"></div>
          </div> :
          <>

            <Box sx={{ display: 'flex' }}>
              <Sidebar />
              <Box
                component="main"
                sx={{
                  flex: 1,
                  p: { xs: 1, sm: 3 },
                }}
              >

                <StyledHeading variant="h4">Faq's</StyledHeading>
                <StyledTabs value={tabIndex} onChange={handleChange}>
                  <StyledTab label="Create Faq" />
                  <StyledTab label="Preview Faq's" />
                </StyledTabs>
                <AnimatePresence mode="wait">
                  <motion.div
                    key={tabIndex}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                  >
                    {tabIndex === 0 && <FaqCreate />}
                    {tabIndex === 1 && <FaqPrivews />}
                  </motion.div>
                </AnimatePresence>
              </Box>
            </Box>
          </>

      }
    </>
  );
}

export default Faq;

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'capitalize',
  minWidth: 70,
  fontWeight: 600,
  fontSize: '0.7rem',
  color: '#422afb',
  position: 'relative',
  zIndex: 2,
  padding: '0px 10px',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  '&.Mui-selected': {
    color: '#fff',
    backgroundColor: '#422afb',
    borderRadius: '5px',
    boxShadow: '0 2px 8px rgba(66, 42, 251, 0.3)',
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  background: 'rgba(66, 42, 251, 0.08)',
  borderRadius: '5px',
  width: 'max-content',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

const StyledHeading = styled(Typography)(({ theme }) => ({
  color: '#422afb',
  fontWeight: 300,
  fontSize: '12px',
  marginBottom: '30px',
  textShadow: '2px 2px 4px rgba(66, 42, 251, 0.3)',
  letterSpacing: '1px',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-10px',
    left: '0',
    width: '50px',
    height: '4px',
    background: 'rgba(66, 42, 251, 0.1)',
    borderRadius: '2px',
  },
}));
