import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../Auth/Auth.css";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Logo from "../../../../assets/Logo.png"
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
  Link,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import logo from "../../../../assets/Logo.png";
import loginImg from "../../../../assets/servicesbg.jpg";
import axios from "axios";
import {ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import siteConfig from "../../../../siteConfig";

const SignupForm = () =>{
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch()
  const [showOtpField, setShowOtpField] = useState(false);
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(120);
  const [canResend, setCanResend] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleVerifyOtp = async () => {
    try {
      await axios.post(`${siteConfig.base_URL}/api/v1/verifyotp`, { ...formData, otp });
      toast.success("Signup successful! You can now log in");
      navigate("/login")
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    let interval;
    if (showOtpField && timer > 0) {
      interval = setInterval(() => {
        setTimer(prev => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setCanResend(true); // Enable resend button after timer expires
    }
    return () => clearInterval(interval);
  }, [showOtpField, timer]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${siteConfig.base_URL}/api/v1/register`, formData);
      if (response.data.message) {
        toast.success(response.data.message);
        setShowOtpField(true);
        setTimer(120);
      } else {
        toast.error(response);
      }
    } catch (error) {
      toast.error(error.response?.data?.message);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleResendOtpApiFunt = async () => {
    try {
      await axios.post(`${siteConfig.base_URL}/api/v1/resendOtp`, { ...formData });
      toast.success("OTP has been resent!");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const handleResendOtp = () => {
    // Call API to resend OTP
    setTimer(120); // Reset the timer
    setCanResend(false); // Disable resend until timer expires
    handleResendOtpApiFunt()
    
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ padding: '10px' }}
    >
      <Grid
      mt={2}
        container
        item
        xs={12}
        md={12}
        sx={{
          backgroundColor: 'white',
          borderRadius: '20px',
          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
          position: 'relative',
        }}
      >
        <Grid item xs={12} md={6} sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={loginImg} alt="Fashion Model" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '20px', boxShadow: "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px" }} />
          <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0))', borderRadius: '20px' }} />
        </Grid>
        <Grid item xs={12} md={6} sx={{ padding: '40px' }}>
        <img src={Logo} alt='logo' style={{height:'48px', marginBottom:"12px"}} />
          <Typography sx={{ marginTop: '15px', marginBottom: '15px', fontWeight: 300, fontSize: "11px", maxWidth: "400px", width: "100%" }}>
            At Door Plus, we understand the importance of a
            well-maintained home. Our team of skilled professionals is
            dedicated to providing top-notch home repair services at
            affordable prices.
          </Typography>
          {!showOtpField ?<>
          <Typography variant="body1" sx={{ marginBottom: '0px', fontWeight:500}}>
            Please signup to your account
          </Typography>
          <form onSubmit={handleSubmit}>
          <TextField
              fullWidth
              margin="normal"
              label="Name"
              variant="outlined"
              name="name"
              required
              value={formData.name}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Email Address"
              variant="outlined"
              name="email"
              required
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              name="password"
              value={formData.password}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
              <FormControlLabel control={<Checkbox defaultChecked />} label="Keep me signed in" />
              <Link component={RouterLink} to="/login" sx={{ color: 'black', textDecoration: 'none' }}>
                login
              </Link>
            </Box>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ marginTop: '20px', padding: '16px 0', backgroundColor: 'black', '&:hover': { backgroundColor: 'grey' } }}
            >
              Signup
            </Button>
          </form>
          </>
          :<>
           <input className="otpInput" placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)} />
         
         {timer > 0 && (
           <>
           <button onClick={handleVerifyOtp} className="otpButton">
           Verify OTP
         </button>
         <div className="timer">{`Resend available in ${formatTime(timer)}`}</div>
           </>
           
         )}
         {canResend && (
           <button onClick={handleResendOtp} className="otpInput">
             Resend OTP
           </button>
         )}
          </>}
        </Grid>
        </Grid>
    <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
    </Grid>
  );
}

export default SignupForm;
