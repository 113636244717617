import { 
  ADD_TO_CART, 
  REMOVE_FROM_CART, 
  CLEAR_CART, 
  ALL_CART_REQUEST, 
  ALL_CART_SUCCESS, 
  ALL_CART_FAIL 
} from "../constant/cartConstant";

const initialState = {
  cart: { services: [] }, // Ensure a valid JSON string
  loading: false,
  error: null, 
};


const cartReducer = (state = initialState, action) => {
  let updatedCart;

  switch (action.type) {
    case ADD_TO_CART:
      const exists = state?.cart?.services?.some((item) => item.serviceId._id === action.payload._id);
      if (exists) return state; // Prevent duplicate items

      updatedCart = {
        ...state.cart,
        services: [...state.cart.services, action.payload], // ✅ Ensure services is an array
      };

      return {
        ...state,
        cart: updatedCart,
      };

    case REMOVE_FROM_CART:
      updatedCart = state.cart.services.filter((item) => item._id !== action.payload);
      return {
        ...state,
        cart: updatedCart,
      };

    case CLEAR_CART:
      return { ...state, cart:{ services: [] } };

    case ALL_CART_REQUEST:
      return { ...state, loading: true, error: null };

    case ALL_CART_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        cart: action.payload 
      };

    case ALL_CART_FAIL:
      return { 
        ...state, 
        loading: false, 
        error: action.payload
      };

    default:
      return state;
  }
};

export default cartReducer;
