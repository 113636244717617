import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import TopNav from '../sidebar/navbar';
import Sidebar from '../sidebar/sidebar';
import ServiceCreate from './serviceCreate';
import ServicesList from './servicesList';
import "../common/commonStyle.css"
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from "framer-motion";

const Service = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  const handleChange = (event, newIndex) => {
    setTabIndex(newIndex);
    setSelectedService(null)
  };
  const handleResetTab=()=>{
    setSelectedService(null)
  }
  const handleEdit = (service) => {
    setSelectedService(service); 
    setTabIndex(1); 
  };

  return (
    <>
      <TopNav />
      <Box height={70} />
      {loading ? (
        <div className="loading">
           <div className="loader"></div>
        </div>
      ) : (
        <div >
          <Box sx={{ display: 'flex' }}>
            <Sidebar />
            <Box component="main" sx={{ flex: 1, p: 3 }}>
              <StyledTabs value={tabIndex} onChange={handleChange}>
                
                <StyledTab label="All Service" />
                <StyledTab label= {selectedService?._id?"Edit Service":"Create Service"} />
              </StyledTabs>
              <AnimatePresence mode="wait">
                <motion.div
                  key={tabIndex}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  {tabIndex === 0 && <ServicesList handleEdit={handleEdit} />}
                  {tabIndex === 1 &&  <ServiceCreate handleResetTab={handleResetTab} serviceData={selectedService} />}
                </motion.div>
              </AnimatePresence>
            </Box>
            </Box>
        </div>
      )}
        
    </>
  );
}

export default Service;

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'capitalize',
  minWidth: 70,
  fontWeight: 600,
  fontSize: '0.7rem',
  color: '#422afb',
  position: 'relative',
  zIndex: 2,
  padding: '4px 10px',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  '&.Mui-selected': {
    color: '#fff',
    backgroundColor: '#422afb',
    borderRadius: '5px',
    boxShadow: '0 2px 8px rgba(66, 42, 251, 0.3)',
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  background: 'rgba(66, 42, 251, 0.08)',
  borderRadius: '5px',
  width: 'max-content',
  padding: '3px',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));