import React from 'react';
import { Grid, Box, Typography, Button } from "@mui/material";
import { styled } from '@mui/system';
import Footer from '../../inc/Footer/footer';
import { useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';

const PremiumButton = styled(Button)(({ theme }) => ({
  marginTop: "20px",
  background: 'linear-gradient(135deg, #000DFF 0%, #6B73FF 100%)',
  color: '#FFFFFF',
  fontWeight: 600,
  borderRadius: 6,
  padding: '10px 24px',
  border: 'none',
  boxShadow: '0 8px 25px rgba(0, 13, 255, 0.1)',
  fontSize: '1rem',
  textTransform: 'none',
  '&:hover': {
    background: 'linear-gradient(135deg, #0009CC 0%, #5158CC 100%)',
  },
  '&:disabled': {
    background: '#CCCCCC',
    color: '#666666',
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  marginTop: "20px",
  background: '#FFFFFF',
  color: '#000000',
  fontWeight: 600,
  borderRadius: 6,
  padding: '10px 24px',
  border: '1px solid #D3D3D3',
  fontSize: '1rem',
  textTransform: 'none',
  '&:hover': {
    background: '#F5F5F5',
  },
}));

const SuccessFullPage = () => {
     const navigate = useNavigate();
     const homeFunct = ()=>{
        navigate("/")
     }
  return (
    <>
      <Box
        sx={{
          marginTop: "120px",
          marginBottom: "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            background: "#FFFFFF",
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            borderRadius: "12px",
            padding: "40px",
            textAlign: "center",
            width: "400px",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#E8F5E9",
              borderRadius: "50%",
              width: "80px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            <Typography
              sx={{
                color: "#4CAF50",
                // fontSize: "30px",
                fontWeight: "bold",
              }}
            >
             <CheckIcon style={{fontSize:"60px"}} />
            </Typography>
          </Box>
          <Typography variant="h6" sx={{ marginTop: "20px", fontWeight: "bold" }}>
            Service Booked
          </Typography>
          <Typography sx={{ color: "#666666", marginTop: "10px" }}>
            Your booking has been successfully.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <PremiumButton onClick={homeFunct}>Home</PremiumButton>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default SuccessFullPage;
