import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, MenuItem, Stack, InputLabel, Select } from '@mui/material';
import TopNav from '../sidebar/navbar';
import Sidebar from '../sidebar/sidebar';
import ServicesList from './servicesList';
import "../common/commonStyle.css";
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from "framer-motion";
import { updateUserBooking } from '../../action/UserServiceBookingAction';
import { useDispatch,useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
const UserBooking = () => {
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [statusData, setStatusData] = useState(['pending', 'confirmed', 'completed', 'cancelled']);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();
  const { updateUserBooking:dataUserBooking,loading:subLoading, error } = useSelector((state) => state.updateUserBooking);

useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    if (isSubmitted) {
      if (dataUserBooking && !subLoading) {
        toast.success(dataUserBooking.message || "Booking updated successfully!");
        setIsSubmitted(false);
      }
      if (error) {
        toast.error(error);
        setIsSubmitted(false);
      }
    }
  }, [dataUserBooking, subLoading, error, isSubmitted]);


  const handleClose = () => {
    setOpenModal(false);
  };

  const handleEdit = (service) => {
    setSelectedBooking(service);
    setSelectedStatus(service.status); // Set the initial status of the dropdown
    setOpenModal(true);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleSubmit = () => {
    // Handle the submission of the updated status
    setIsSubmitted(true); 
    dispatch(updateUserBooking(selectedBooking._id, selectedStatus));
    handleClose();
  };

  return (
    <>
      <TopNav />
      <Box height={70} />
      {loading ? (
        <div className="loading">
           <div className="loader"></div>
        </div>
      ) : (
        <div>
          <Box sx={{ display: 'flex' }}>
            <Sidebar />
            <Box component="main" sx={{ flex: 1, p: 3 }}>
              <StyledTabs>
                <StyledTab label="All Booking" />
              </StyledTabs>
              <AnimatePresence mode="wait">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <ServicesList handleEdit={handleEdit} handleData={openModal} />
                </motion.div>
              </AnimatePresence>
            </Box>
          </Box>
          <StyledDialog open={openModal} onClose={handleClose}>
            <DialogTitle sx={{ textAlign: "center", fontWeight: 700 }}>
              Choose Status
            </DialogTitle>
            <DialogContent>
              <Stack spacing={1}>
                <Select
                  fullWidth
                  label="Status"
                  id="status"
                  name="status"
                  variant="standard"
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  displayEmpty
                  inputProps={{
                    style: { borderColor: "#c4c4c4" },
                  }}
                >
                  {statusData.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", pb: 2 }}>
              <Button
                variant="contained"
                sx={{
                  background: "linear-gradient(135deg, #000DFF 0%, #6B73FF 100%)",
                  color: "#FFF",
                  fontWeight: 600,
                  textTransform: "none",
                  borderRadius: "6px",
                  px: 3,
                }}
                onClick={handleSubmit}
              >
                Confirm
              </Button>
            </DialogActions>
          </StyledDialog>
          
        </div>
      )}
       <ToastContainer
                          position="top-right"
                          autoClose={3000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          theme="light"
                      />
    </>
  );
}

export default UserBooking;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "12px",
    padding: "20px",
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'capitalize',
  minWidth: 70,
  fontWeight: 600,
  fontSize: '0.7rem',
  color: '#422afb',
  position: 'relative',
  zIndex: 2,
  padding: '4px 10px',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  '&.Mui-selected': {
    color: '#fff',
    backgroundColor: '#422afb',
    borderRadius: '5px',
    boxShadow: '0 2px 8px rgba(66, 42, 251, 0.3)',
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  background: 'rgba(66, 42, 251, 0.08)',
  borderRadius: '5px',
  width: 'max-content',
  padding: '3px',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));