export const USER_BOOKING_REQUEST = 'USER_BOOKING_REQUEST';
export const USER_BOOKING_SUCCESS = 'USER_BOOKING_SUCCESS';
export const USER_BOOKING_FAIL = 'USER_BOOKING_FAIL';
export const USER_BOOKING_RESET = 'USER_BOOKING_RESET';

export const ALL_USER_BOOKING_REQUEST = 'ALL_USER_BOOKING_REQUEST';
export const ALL_USER_BOOKING_SUCCESS = 'ALL_USER_BOOKING_SUCCESS';
export const ALL_USER_BOOKING_FAIL = 'ALL_USER_BOOKING_FAIL';

export const DELETE_USER_BOOKING_REQUEST = 'DELETE_USER_BOOKING_REQUEST';
export const DELETE_USER_BOOKING_SUCCESS = 'DELETE_USER_BOOKING_SUCCESS';
export const DELETE_USER_BOOKING_FAIL = 'DELETE_USER_BOOKING_FAIL';
export const DELETE_USER_BOOKING_RESET = 'DELETE_USER_BOOKING_RESET';

export const UPDATE_USER_BOOKING_REQUEST = 'UPDATE_USER_BOOKING_REQUEST';
export const UPDATE_USER_BOOKING_SUCCESS = 'UPDATE_USER_BOOKING_SUCCESS';
export const UPDATE_USER_BOOKING_FAIL = 'UPDATE_USER_BOOKING_FAIL';
export const UPDATE_USER_BOOKING_RESET = 'UPDATE_USER_BOOKING_RESET';


