import React from "react";
import {
    Container, Typography, CardContent, Box, IconButton, Grid, Chip, Modal, Backdrop, Fade, Avatar, List, ListItem, ListItemText, Button
} from '@mui/material';
import { styled } from '@mui/system';
const PremiumButton = styled(Button)(({ theme }) => ({
    marginTop: "20px",
    background: 'linear-gradient(135deg, #000DFF 0%, #6B73FF 100%)',
    color: '#FFFFFF',
    fontWeight: 600,
    borderRadius: 6,
    padding: '6px 16px',
    border: '3px solid white',
    boxShadow: '0 8px 25px rgba(0, 13, 255, 0.1)',
    fontSize: '1rem',
    textTransform: 'none',
    '&:hover': {
        background: 'linear-gradient(135deg, #0009CC 0%, #5158CC 100%)'
    },
    '&:disabled': {
        background: '#CCCCCC',
        color: '#666666',
    },
}));

const PremiumRedButton = styled(Button)(({ theme }) => ({
  marginTop: "20px",
  background: 'linear-gradient(135deg, #ff2800 0%, #6B73FF 100%)',
  color: '#FFFFFF',
  fontWeight: 600,
  borderRadius: 6,
  padding: '6px 16px',
  border: '3px solid white',
  boxShadow: '0 8px 25px rgba(0, 13, 255, 0.1)',
  fontSize: '1rem',
  textTransform: 'none',
  '&:hover': {
      background: 'linear-gradient(135deg, #ff2800 0%, #5158CC 100%)'
  },
  '&:disabled': {
      background: '#CCCCCC',
      color: '#666666',
  },
}));
const LoginModal = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <Modal open={show} onClose={onClose} closeAfterTransition BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 500, style: { backdropFilter: 'blur(4px)' } }}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{
                        bgcolor: 'white', boxShadow: 24, p: 3, minWidth: 200, borderRadius: 4,
                        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                        '&:hover': { transform: 'translateY(-5px)', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)' }, zIndex: 1301
                    }}>
                      <div className="modal-content">
         <h2>Login</h2>
        <p>You have to login for booking service</p>
        <PremiumRedButton  onClick={onClose} size="small" sx={{ mt: 1 }}>Cancel</PremiumRedButton>
        <PremiumButton onClick={() => window.location.href = "/login"} size="small" sx={{ mt: 1 }}>OK</PremiumButton>
      </div>
                    </Box>
                </Modal>
  );
};

export default LoginModal;
