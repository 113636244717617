import React, { useRef, useState, useEffect } from "react";
import {
    Button,
    Card,
    Grid,
    IconButton,
    InputLabel,
    Stack,
    TextField,
    Tooltip,
    Typography,
    Select,
    MenuItem
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createService, updateService } from "../../action/serviceAction";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uploadimg from "../icon/image.png"
import { perticularSubCetegory } from "../../action/subCategory";
import { getCategory } from "../../action/catgoryAction";

const ServiceCreate = ({ serviceData, handleResetTab }) => {
    const { getAllCategory } = useSelector((state) => state.getAllCategory);
    const { fetchAllSubCategory } = useSelector((state) => state.fetchAllSubCategory);
    const [service, setService] = useState({
        name: "",
        _id: "",
        description: "",
        image: null,
        categoryId: "",
        subcategoryId: "",
        duration: "",
        price: {
            basePrice: "",
            discount: "",
            finalPrice: "",
            taxPercentage: "",
            totalPrice: ""
        }
    });
    const dispatch = useDispatch();
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "duration" && !/^\d*$/.test(value)) return;
        if (name === "price" && !/^\d*\.?\d*$/.test(value)) return;
        setService((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    useEffect(() => {
        dispatch(getCategory())
    }, [dispatch]);
    useEffect(() => {
        if (serviceData) {
            setService(serviceData);
            dispatch(perticularSubCetegory(serviceData.categoryId))
        }
    }, [serviceData]);
    const handleSubmit = (e) => {
        e.preventDefault();
        const { name, description, subcategoryId, price } = service;

        if (!name || !description || !subcategoryId || !price.basePrice) {
            toast.error("Please fill all required fields");
            return;
        }

        const formData = new FormData();
        formData.append("name", service.name);
        formData.append("description", service.description);
        formData.append("categoryId", service.categoryId);
        formData.append("subcategoryId", service.subcategoryId);
        formData.append("duration", service.duration);
        formData.append("basePrice", price.basePrice);
        formData.append("discount", price.discount);
        formData.append("finalPrice", price.finalPrice);
        formData.append("taxPercentage", price.taxPercentage);
        formData.append("totalPrice", price.totalPrice);

        if (service.image) {
            formData.append("image", service.image);
        }

        if (service._id) {
            dispatch(updateService(service._id, formData));
            toast.success("Service updated successfully");
        } else {
            dispatch(createService(formData));
            toast.success("Service added successfully");
        }

        handleResetTab();
        setService({
            name: "",
            description: "",
            categoryId: "",
            subcategoryId: "",
            duration: "",
            price: {
                basePrice: "",
                discount: "",
                finalPrice: "",
                taxPercentage: "",
                totalPrice: ""
            },
            image: null
        });
    };



    const fileInputRef = useRef(null);

    const handleUploadButtonClick = () => {
        fileInputRef.current.click();
    };
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        console.log(file, "ffff")
        if (file) {
            setService((prevState) => ({
                ...prevState,
                image: file,
            }));
        }
    };
    const handleRemoveImage = () => {
        setService((prevState) => ({
            ...prevState,
            image: null,
        }));
    };
    const menuCatFilter = (value) => {
        setService((prevState) => ({
            ...prevState,
            categoryId: value,
        }));
        dispatch(perticularSubCetegory(value))
    }
    const handlePriceChange = (e) => {
        const { name, value } = e.target;
        if (!/^\d*\.?\d*$/.test(value)) return; // Ensure only numbers are entered

        setService((prevState) => {
            const newPricing = { ...prevState.price, [name]: value };

            // Calculate `finalPrice` and `totalPrice` dynamically
            const base = parseFloat(newPricing.basePrice) || 0;
            const discount = parseFloat(newPricing.discount) || 0;
            const tax = parseFloat(newPricing.taxPercentage) || 0;

            newPricing.finalPrice = base - (base * (discount / 100));
            newPricing.totalPrice = newPricing.finalPrice + (newPricing.finalPrice * (tax / 100));

            return { ...prevState, price: newPricing };
        });
    };
    return (
        <>
            <Grid container style={{ marginTop: "20px" }} component="form" onSubmit={handleSubmit}>
                <Grid item xs={12} sm={12} md={8}>
                    <Card
                        variant="outlined"
                        style={{ padding: "30px", margin: "15px", borderRadius: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px" }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={6} style={{ marginBottom: "5px" }}>
                                <Stack spacing={1}>
                                    <InputLabel
                                        htmlFor="Service Name"
                                        style={{ marginBottom: "5px", fontSize: "12px", color: "#422afb" }}
                                    >
                                        Service Name<span style={{ color: "#f44336" }}>*</span>
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        placeholder="Service Name"
                                        id="categoryTitle"
                                        name="name"
                                        variant="outlined"
                                        value={service.name}
                                        onChange={handleChange}
                                        InputProps={{
                                            style: {
                                                borderColor: "#422afb",
                                                padding: "10px 0px",
                                                fontSize: '12px'
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: { fontSize: "12px" },
                                        }}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <InputLabel
                                        htmlFor="category"
                                        style={{ marginBottom: "5px", fontSize: "12px", color: "#4b4b4b" }}
                                    >
                                        Category<span style={{ color: "#f44336" }}>*</span>
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        label="category"
                                        id="category"
                                        name="categoryId"
                                        variant="outlined"
                                        value={service.categoryId}
                                        onChange={(e) => menuCatFilter(e.target.value)}

                                        displayEmpty
                                        inputProps={{
                                            style: { borderColor: "#c4c4c4" },
                                        }}
                                    >

                                        <MenuItem value="">
                                            Select Category
                                        </MenuItem>
                                        {
                                            getAllCategory?.categories?.map((item) => (
                                                <MenuItem value={item._id}
                                                    key={item._id}>{item.title}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <InputLabel
                                        htmlFor="category"
                                        style={{ marginBottom: "5px", fontSize: "12px", color: "#4b4b4b" }}
                                    >
                                        Sub Category<span style={{ color: "#f44336" }}>*</span>
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        label="category"
                                        id="category"
                                        name="subcategoryId"
                                        variant="outlined"
                                        value={service.subcategoryId}
                                        onChange={handleChange}

                                        displayEmpty
                                        inputProps={{
                                            style: { borderColor: "#c4c4c4" },
                                        }}
                                    >

                                        <MenuItem value="">
                                            Select Sub Category
                                        </MenuItem>
                                        {
                                            fetchAllSubCategory?.getsubcategory?.map((item) => (
                                                <MenuItem value={item._id}
                                                    key={item._id}>{item.SubcategoryTitle}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Stack>
                            </Grid>
                            {/* <Grid item xs={6} style={{ marginBottom: "5px" }}>
                                <Stack spacing={1}>
                                    <InputLabel
                                        htmlFor="Price"
                                        style={{ marginBottom: "5px", fontSize: "12px", color: "#422afb" }}
                                    >
                                        Price<span style={{ color: "#f44336" }}>*</span>
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        size="small"
                                       placeholder="Price"
                                        id="Price"
                                        name="price"
                                        variant="outlined"
                                        value={service.price}
                                        onChange={handleChange}
                                      InputProps={{
                                            style: { borderColor: "#422afb", 
                                                    padding:"10px 0px",
                                                    fontSize:'12px'
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: { fontSize: "12px" },
                                        }}
                                    />
                                </Stack>
                            </Grid> */}

                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <InputLabel>Base Price<span style={{ color: "#f44336" }}>*</span></InputLabel>
                                    <TextField
                                        fullWidth
                                        size="small" InputProps={{
                                            style: {
                                                borderColor: "#422afb",
                                                padding: "10px 0px",
                                                fontSize: '12px'
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: { fontSize: "12px" },
                                        }} placeholder="Base Price" name="basePrice" variant="outlined" value={service.price.basePrice} onChange={handlePriceChange} />
                                </Stack>
                            </Grid>

                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <InputLabel>Discount %</InputLabel>
                                    <TextField InputProps={{
                                        style: {
                                            borderColor: "#422afb",
                                            padding: "10px 0px",
                                            fontSize: '12px'
                                        },
                                    }}
                                        InputLabelProps={{
                                            style: { fontSize: "12px" },
                                        }} fullWidth size="small" placeholder="Discount" name="discount" variant="outlined" value={service.price.discount} onChange={handlePriceChange} />
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <InputLabel>Tax (%)</InputLabel>
                                    <TextField InputProps={{
                                        style: {
                                            borderColor: "#422afb",
                                            padding: "10px 0px",
                                            fontSize: '12px'
                                        },
                                    }}
                                        InputLabelProps={{
                                            style: { fontSize: "12px" },
                                        }} fullWidth size="small" name="taxPercentage" value={service.price.taxPercentage} onChange={handlePriceChange} />
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <InputLabel>Total Price</InputLabel>
                                    <TextField InputProps={{
                                        style: {
                                            borderColor: "#422afb",
                                            padding: "10px 0px",
                                            fontSize: '12px'
                                        },
                                    }}
                                        InputLabelProps={{
                                            style: { fontSize: "12px" },
                                        }} fullWidth size="small" placeholder="Total Price" name="totalPrice" variant="outlined" value={service.price.totalPrice} disabled />
                                </Stack>
                            </Grid>
                            <Grid item xs={6} style={{ marginBottom: "5px" }}>
                                <Stack spacing={1}>
                                    <InputLabel
                                        htmlFor="Duration"
                                        style={{ marginBottom: "5px", fontSize: "12px", color: "#422afb" }}
                                    >
                                        Duration (minutes)<span style={{ color: "#f44336" }}>*</span>
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        placeholder="Enter Duration"
                                        id="Duration"
                                        name="duration"
                                        variant="outlined"
                                        value={service.duration}
                                        onChange={handleChange}
                                        InputProps={{
                                            style: {
                                                borderColor: "#422afb",
                                                padding: "10px 0px",
                                                fontSize: "12px",
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: { fontSize: "12px" },
                                        }}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={1}>
                                    <InputLabel
                                        htmlFor="description"
                                        style={{ marginBottom: "5px", fontSize: "12px", color: "#422afb" }}
                                    >
                                        Description<span style={{ color: "#f44336" }}>*</span>
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        size="small"
                                        placeholder="Description"
                                        id="description"
                                        name="description"
                                        variant="outlined"
                                        value={service.description}
                                        onChange={handleChange}
                                        InputProps={{
                                            style: {
                                                borderColor: "#422afb",
                                                padding: "10px 0px",
                                                fontSize: '12px',
                                                padding: '10px'
                                            },
                                        }}

                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Card
                        variant="outlined"
                        style={{
                            borderRadius: "20px",
                            padding: "4px",
                            margin: "14px",
                            height: "280px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px"

                        }}
                    >
                        {service.image ? (
                            <>
                                <img src={service.image?.url ? service.image.url : URL.createObjectURL(service.image)} alt="Uploaded" style={{ height: "160px", marginBottom: "10px" }} />
                                <Button onClick={handleRemoveImage} variant="outlined" color="secondary">
                                    Remove Image
                                </Button>
                            </>
                        ) : (
                            <>
                                <Typography variant="h6" style={{ marginBottom: "2px", color: "#422afb", fontSize: "12px" }}>
                                    Upload Image
                                </Typography>
                                <Card
                                    variant="outlined"
                                    style={{
                                        padding: "20px",
                                        margin: "10px",
                                        height: "160px",
                                        border: "2px #422afb dashed",
                                        width: "200px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        onChange={handleFileChange}
                                    />
                                    <IconButton onClick={handleUploadButtonClick} color="primary">
                                        <img
                                            style={{
                                                height: "100px"
                                            }}
                                            src={uploadimg} alt="upload img" />
                                    </IconButton>
                                </Card>
                            </>
                        )}
                    </Card>
                    <Button type="submit" variant="contained" color="primary" style={{
                        background: "#422afb", width: "100%", padding: "12px 34px", borderRadius: "10px"
                    }} disabled={!service.name || !service.description || !service.image}>
                        {service._id ? "Edit" : "Submit"}
                    </Button>
                </Grid>
            </Grid>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    );
};

export default ServiceCreate;




