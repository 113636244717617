import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Box, IconButton, Drawer, Typography,Menu, MenuItem, Avatar, useMediaQuery } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ProfilePage from '../../mobile/profle/profile';
import Logo from "../../../../assets/Logo.png"
import { useDispatch, useSelector } from "react-redux";
import { loadUser, loginUser, logoutUser } from "../../../../action/UserAuthActions.js";
function Navbar({user}) {
  const isMobile = useMediaQuery('(max-width: 700px)');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);
  console.log("fffffffffd", user)
  // const { user, isAuthenticated, loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  // useEffect(() => {
  //   dispatch(loadUser());
  // }, [dispatch,isAuthenticated, loading]);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 0) {
  //       setIsScrolled(true);
  //     } else {
  //       setIsScrolled(false);
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  const getAppBarBackgroundColor = () => {
    if (location.pathname === '/') {
      return 'rgba(0, 0, 0, 0.5)';
    }
    return 'rgb(242, 242, 242)';
  };
  const getAppColor =()=>{
    if (location.pathname === '/') {
      return 'white';
    }
    return '#000';
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', width: '100%' }}>
      <ProfilePage />
    </Box>
  );
const handleLogout=()=>{
  dispatch(logoutUser())
  handleMenuClose();
  navigate("/")
}
  return (
    <>
      <AppBar
        style={styles.appBar}
        position="absolute"
        elevation={0}
        sx={{
          zIndex: 1000,
          background: getAppBarBackgroundColor(),
          color: "#fff",
          padding: '10px 0',
        }}
      >
        <Toolbar sx={styles.toolbar}>
          {isMobile ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  padding: '9px 0px',
                  ...(isScrolled && styles.fixedMobileHeader),
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={handleDrawerToggle}>
                    <Avatar src="/path-to-avatar-image.jpg" sx={{ width: 40, height: 40, mr: 1 }} />
                    <Typography
                      variant="subtitle1"
                      sx={{
                        bgcolor: isScrolled ? 'black' : 'white',
                        px: 2,
                        py: 0.5,
                        borderRadius: 20,
                        fontSize: '0.9rem',
                        fontWeight: 'bold',
                        color: isScrolled ? 'white' : 'black',
                        transition: 'all 0.3s ease',
                      }}
                    >
                      Hello!
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerToggle}
                PaperProps={{
                  sx: {
                    width: '100%',
                    maxWidth: '100vw',
                  },
                }}
              >
                {drawer}
              </Drawer>
            </>
          ) : (
            <>
            <img src={Logo} alt='logo' style={{height:'48px', marginBottom:"12px"}} />
              {/* <Typography component="div" style={styles.logo}>Door Plus</Typography> */}
              <Box>
                <Button sx={{...styles.navButton, color:getAppColor()}} component={Link} to={'/'}>Home</Button>
                <Button sx={{...styles.navButton, color:getAppColor()}}>My Bookings</Button>
                {user?.name?
                <Button  sx={{...styles.navButton, color:getAppColor(),  cursor: "pointer",minWidth: "unset", }} onClick={handleMenuOpen}>
                <Avatar src="https://avatar.iran.liara.run/public/boy" sx={{ width: 32, height: 32, marginRight: 1 }} />
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {user?.name}
                </Typography>
              </Button>
                  // <Button sx={{...styles.navButton, color:getAppColor()}} component={Link} >{user?.name}</Button>
                :<Button sx={{...styles.navButton, color:getAppColor()}} component={Link} to={'/login'}>Sign In</Button>}
                 
              </Box>
              <Menu 
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  disableScrollLock={true}
                 PaperProps={{
                  sx: {
                    width: 180, // Set custom width
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Optional: Add shadow for better UI
                    borderRadius: 2, // Optional: Rounded corners
                    mt: 1,
                  },
                }}
                 anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
            <MenuItem onClick={handleMenuClose} component={Link} to="/profile">Profile</MenuItem>
            <MenuItem onClick={handleMenuClose} component={Link} to="/bookings">My Bookings</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Navbar;

const styles = {
  appBar: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 1000,
    padding: '8px',
    borderBottom: '1px solid',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 700,
    fontSize: '2rem',
    marginRight: '20px',
  },
  navButton: {
    fontWeight: 500,
    fontSize: '18px',
    fontFamily: "'Roboto', sans-serif",
    padding: '0.5rem 1rem',
    marginLeft: '15px',
    marginRight: '15px',
    textTransform: 'none',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
    },
  },
  fixedMobileHeader: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    backdropFilter: 'blur(10px)',
    zIndex: 1000,
    padding: '12px 16px',
    transition: 'all 0.3s ease',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};
