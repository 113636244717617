import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Button, Dialog, DialogContent, IconButton, } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../../../action/catgoryAction";
import { ArrowForward, ArrowBack, Close, } from "@mui/icons-material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from 'framer-motion';
import { styled } from '@mui/system';
import img from "../../../../assets/46153.jpg"

const AllCategory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getAllCategory, } = useSelector((state) => state.getAllCategory);
  const [categoryData, setCategoryData] = useState(getAllCategory);

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  useEffect(() => {
    if (getAllCategory) setCategoryData(getAllCategory);
  }, [getAllCategory]);

  const navigateCategory = (obj) => navigate(`/category/${obj._id}`, { state: { selectedCategoryId: obj._id } });

  const CustomArrow = ({ onClick, direction }) => (
    <ArrowButton onClick={onClick} style={{ [direction]: 0 }}>
      {direction === 'right' ? <ArrowForward style={{ fontSize: '0.9rem' }} /> : <ArrowBack style={{ fontSize: '0.9rem' }} />}
    </ArrowButton>
  );

  return (
    <Box style={{ marginTop: "8%" }} className="doorplusAllcatmt">
      <Box style={{ padding: "5px 20px" }} className="mediaAllcatBoxbpd">
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginBottom: "5px" }}>
            <Grid container justifyContent="space-between" alignItems="center" style={{ padding: "9px" }}>
              <Grid item xs={6}>
                <Typography style={styles.heading} className="mediaAllHead">All Category</Typography>
                <Typography style={styles.subTitle} className="allcatsubTitle">Always Be ready to choose best service</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "end" }}>
                <Typography style={styles.viewAll} className="mobilemediaview">
                  <span style={styles.viewAllSpan}>view all <ArrowForward style={{ fontSize: "9px" }} className="doorplusviewicon" /></span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Carousel responsive={responsive} autoPlay={false} autoPlaySpeed={3000} infinite={true}
              customRightArrow={<CustomArrow direction="right" />} customLeftArrow={<CustomArrow direction="left" />}>
              {(categoryData?.categories || []).map((service) => (
                <ServiceCard key={service._id} onClick={() => navigateCategory(service)}
                  whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.95 }}
                  initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.4 }}>
                  <motion.img src={service.image?.url} alt={service.title} style={styles.image} className='topsaloonimg-mb'
                    whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }} />
                  <Typography variant="subtitle1" sx={styles.title}>{service.title}</Typography>
                </ServiceCard>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AllCategory;

const responsive = {
  superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 3 },
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 5 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 3 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 2 },
};

const ArrowButton = styled(Button)(({ theme }) => ({
  boxShadow: '0 10px 30px rgba(0, 13, 255, 0.1)',
  position: 'absolute', top: '50%', transform: 'translateY(-50%)', backgroundColor: 'white', color: '#000DFF',
  minWidth: '30px', width: '40px', height: '40px', borderRadius: '50%', '&:hover': { backgroundColor: 'white' }
}));

const ServiceCard = styled(motion.div)(({ theme }) => ({
  borderRadius: 17, margin: '10px', padding: theme.spacing(2), cursor: 'pointer',
  boxShadow: '0 10px 30px rgba(0, 13, 255, 0.1)', background: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)', border: '2px solid rgba(255, 255, 255, 0.3)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': { transform: 'translateY(-5px)', boxShadow: '0 15px 35px rgba(0, 13, 255, 0.2)' },
}));

const styles = {
  closeButton: { position: 'absolute', color: 'white', left: 16, top: 16, background: '#000dff70', },
  heading: { fontSize: "25px", fontWeight: 700, color: "#000dffbf", textTransform: "capitalize", marginBottom: "4px" },
  viewAll: { fontSize: "12px", fontWeight: "400", color: "black", textTransform: "capitalize", },
  subTitle: { fontSize: "11px", fontWeight: "400", color: "grey", textTransform: "capitalize" },
  viewAllSpan: { background: "linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)", borderRadius: "10px", padding: "14px", color: 'white', cursor: "pointer" },
  image: { width: '100%', height: 150, borderRadius: 12 },
  title: { mt: 0.5, fontWeight: 600, fontSize: '0.7rem', color: 'grey' },
};