import React, { useState, useEffect } from 'react';
import {
    Box, Typography, Button, List, ListItem, ListItemText, Divider, IconButton, Paper, styled, Avatar, CircularProgress
} from '@mui/material';
import {
    ArrowBack, ChevronRight, History, Help, Info, Logout
} from '@mui/icons-material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Link } from 'react-router-dom';
import { keyframes } from '@mui/system';



const ProfilePage = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    return (

        <React.Fragment>
            {
                isLoading ? (
                  <div>
                    loading
                  </div>
                ) : (
                    <PageWrapper>
                        <Box sx={{
                            width: '100%',
                            maxWidth: '100vw',
                            margin: 'auto',
                            bgcolor: '#f0f4ff',
                            minHeight: '100vh',
                            position: 'relative',
                            overflow: 'hidden',
                        }}>
                           
                            <SmallBall sx={{ top: '20%', left: '10%', width: '50px', height: '50px',  }} />
                            <SmallBall sx={{ top: '60%', right: '15%', width: '30px', height: '30px', }} />
                            <SmallBall sx={{ bottom: '15%', left: '20%', width: '40px', height: '40px',  }} />

                            <Box
                                sx={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: 2,
                                    bgcolor: 'rgba(255, 255, 255, 0.1)',
                                    backdropFilter: 'blur(10px)',
                                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                    zIndex: 1000
                                }}
                            >
                                <IconButton component={Link} to="/" edge="start" sx={{
                                    mr: 2, color: 'black',
                                    width: 40,
                                    height: 40,
                                    background: 'rgba(255, 255, 255, 0.25)',
                                    backdropFilter: 'blur(1px)',
                                
                                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
                                }}>
                                    <ArrowBack style={{ fontSize: '1.1rem', fontWeight: 400 , }} />
                                </IconButton>
                                <Typography
                                    sx={{
                                        fontSize: '0.9rem',
                                        fontWeight: 500,
                                        color: 'black',
                                    }}
                                >
                                    My Account
                                </Typography>
                            </Box>

                            <Box sx={{ p: 2, mt: 10, position: 'relative', zIndex: 1 ,}}>
                                <StyledPaper sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex' }}>
                                        <Avatar sx={{ width: 56, height: 56, background: 'black', mr: 2 }}>GU</Avatar>
                                    </Box>
                                    <Typography variant="body2" sx={{ color: 'black', textAlign: "left", padding: "2px" }}>
                                        Login to access your profile
                                    </Typography>
                                    <Button sx={{ background: 'black', color: 'white', borderRadius: 20, textTransform: 'none', px: 3, '&:hover': { bgcolor: '#6B73FF' } }}>
                                        Login
                                    </Button>
                                </StyledPaper>

                                <StyledPaper sx={{ mt: 2 }}>
                                    <List disablePadding>
                                        {menudata.map((item, index) => (
                                            <React.Fragment key={item.text}>
                                                <StyledListItem button component={Link} to={item.link}>
                                                    <IconWrapper sx={{ minWidth: 40 }}>{item.icon}</IconWrapper>
                                                    <ListItemText primary={item.text} primaryTypographyProps={{ variant: 'body2', fontWeight: 500 }} />
                                                    <ChevronRight color="action" fontSize="small" />
                                                </StyledListItem>
                                                {index < menudata.length - 1 && <Divider sx={{ opacity: 0.2 }} />}
                                            </React.Fragment>
                                        ))}
                                    </List>
                                </StyledPaper>

                                <StyledPaper sx={{ p: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box sx={{ bgcolor: '#e3f2fd', borderRadius: '50%', width: 40, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 2 }}>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#1976d2' }}>CU</Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="body2" sx={{ fontWeight: 'medium' }}>Contact Us</Typography>
                                             
                                            </Box>
                                        </Box>
                                        <ChevronRight color="action" fontSize="small" />
                                    </Box>
                                </StyledPaper>
                                <StyledPaper sx={{ p: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <IconWrapper sx={{ bgcolor: 'rgba(244, 67, 54, 0.1)', color: '#F44336' }}>
                                                <Logout fontSize="small" />
                                            </IconWrapper>
                                            <Typography variant="body1" sx={{ fontWeight: 500, color: '#F44336' }}>Log Out</Typography>
                                        </Box>
                                        <ChevronRight style={{ color: '#F44336' }} />
                                    </Box>
                                </StyledPaper>
                            </Box>

                            <Box sx={{ p: 2, textAlign: 'center', position: 'relative', zIndex: 1 }}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'black' }}>
                               Door Plus
                                </Typography>
                                <Typography variant="caption" color="text.secondary" display="block" sx={{ mt: 0.5 }}>
                                    Made with ♥ in India
                                </Typography>
                            </Box>
                        </Box>
                    </PageWrapper>
                )
            }

        </React.Fragment>

    );
};

export default ProfilePage;


const menudata = [
    { text: 'My Profile', icon: <AccountCircleOutlinedIcon fontSize="small" />, link: '/account/edit-Profile' },
    { text: 'Booking History', icon: <History fontSize="small" />, link: '/Booking/my-bookings' },
    { text: 'Need help?', icon: <Help fontSize="small" />, link: '/support/need-help?' },
    { text: 'About Us', icon: <Info fontSize="small" />, link: '/company/about-us' },
];


const floatAnimation = keyframes`
  0% { transform: translate(0, 0); }
  50% { transform: translate(10px, 10px); }
  100% { transform: translate(0, 0); }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const PageWrapper = styled(Box)(({ theme }) => ({
    opacity: 0,
    animation: `${fadeIn} 0.5s ease-out forwards`,
    animationDelay: '0.1s',
}));


const StyledPaper = styled(Paper)(({ theme }) => ({
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
    backdropFilter: 'blur(1px)',
    WebkitBackdropFilter: 'blur(4px)',
    border: '2px solid rgba(255, 255, 255, 0.18)',
    borderRadius: 16,
    marginBottom: theme.spacing(2),
    backgroundColor: 'rgba(255, 255, 255, 0.25)',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 12px 40px 0 rgba(31, 38, 135, 0.45)',
    },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255, 255, 255, 0.25)',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
    backdropFilter: 'blur(10px)',
    marginRight: theme.spacing(2),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderRadius: 8,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        transform: 'scale(1.02)',
    },
}));



const SmallBall = styled(Box)(({ theme }) => ({
    position: 'absolute',
    borderRadius: '50%',
    background: 'black',
    opacity: 0.6,
    animation: `${floatAnimation} 6s ease-in-out infinite`,
}));
