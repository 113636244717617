import React from 'react'
import { Box,List, ListItem, ListItemText, Divider,Paper } from "@mui/material";
import {Link } from 'react-router-dom';
import { styled } from '@mui/system';
import { ChevronRight, History, Help, Info
} from '@mui/icons-material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import 'react-toastify/dist/ReactToastify.css';
const menudata = [
    { text: 'My Profile', icon: <AccountCircleOutlinedIcon fontSize="small" />, link: '/account/edit-Profile' },
    { text: 'Booking History', icon: <History fontSize="small" />, link: '/Booking/my-bookings' },
    { text: 'Need help?', icon: <Help fontSize="small" />, link: '/support/need-help?' },
    { text: 'About Us', icon: <Info fontSize="small" />, link: '/company/about-us' },
];
const ProfileSideBar=()=> {
  return (
    <StyledPaper>
                                    <List disablePadding>
                                        {menudata.map((item, index) => (
                                            <React.Fragment key={item.text}>
                                                <StyledListItem button component={Link} to={item.link}>
                                                    <IconWrapper sx={{ minWidth: 40 }}>{item.icon}</IconWrapper>
                                                    <ListItemText primary={item.text} primaryTypographyProps={{ variant: 'body2', fontWeight: 500 }} />
                                                    <ChevronRight color="action" fontSize="small" />
                                                </StyledListItem>
                                                {index < menudata.length - 1 && <Divider sx={{ opacity: 0.2 }} />}
                                            </React.Fragment>
                                        ))}
                                    </List>
                                </StyledPaper>
  )
}
const StyledPaper = styled(Paper)(({ theme }) => ({
   // boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
    // backdropFilter: 'blur(1px)',
    // WebkitBackdropFilter: 'blur(4px)',
    border: '2px solid rgba(255, 255, 255, 0.18)',
    borderRadius: 16,
    marginBottom: theme.spacing(2),
    backgroundColor: 'rgb(242, 242, 242)',
}));

const IconWrapper = styled(Box)(({ theme }) => ({
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255, 255, 255, 0.25)',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
    backdropFilter: 'blur(10px)',
    marginRight: theme.spacing(2),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderRadius: 8,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        transform: 'scale(1.02)',
    },
}));
export default ProfileSideBar
