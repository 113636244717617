import {
    ALL_SERVICE_FAIL,
    ALL_SERVICE_REQUEST,
    ALL_SERVICE_SUCCESS,
    SERVICE_FAIL,
    SERVICE_REQUEST,
    SERVICE_SUCCESS,
    DELETE_SERVICE_REQUEST,
    DELETE_SERVICE_SUCCESS,
    DELETE_SERVICE_FAIL,
    UPDATE_SERVICE_REQUEST,
    UPDATE_SERVICE_SUCCESS,
    UPDATE_SERVICE_FAIL
    // CATEGORY_FAIL,
    // CATEGORY_REQUEST,
    // CATEGORY_SUCCESS,
    // DELETE_CATEGORY_FAIL,
    // DELETE_CATEGORY_REQUEST,
    // DELETE_CATEGORY_SUCCESS,
    // UPDATE_CATEGORY_FAIL,
    // UPDATE_CATEGORY_REQUEST,
    // UPDATE_CATEGORY_SUCCESS,
  } from "../constant/serviceConstant";
  import siteConfig from "../siteConfig";
  import axios from "axios";
  
  export const createService = (formData) => async (dispatch) => {
    try {
      dispatch({ type: SERVICE_REQUEST });
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: localStorage.getItem("token"),
        },
      };
      const { Data } = await axios.post(
        `${siteConfig.base_URL}/api/v1/admin/service/new`,
        formData,
        config
      );
      dispatch({ type: SERVICE_SUCCESS, payload: Data });
    } catch (error) {
      dispatch({ type: SERVICE_FAIL, payload: error.message });
    }
  };
  export const getAllService = () => async (dispatch) => {
    try {
      dispatch({ type: ALL_SERVICE_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.get(
        `${siteConfig.base_URL}/api/v1/Allservices`,
        config
      );
      dispatch({ type: ALL_SERVICE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: ALL_SERVICE_FAIL, payload: error.message });
    }
  };
  
  export const deleteService = (_id) => async (dispatch) => {
    try {
      dispatch({
        type: DELETE_SERVICE_REQUEST
      });
      const config = {
        headers: {
          "content-type": "application/json",
          token: localStorage.getItem("token"),
        }
      };
      const {data} = await axios.delete(
        `${siteConfig.base_URL}/api/v1/service/${_id}`,
        config
      );
      dispatch({type:DELETE_SERVICE_SUCCESS,payload:data})
    } catch (error) {
     dispatch({
      type:DELETE_SERVICE_FAIL ,payload:error.message
     })
    }
  }
  
  export const updateService = (id, service) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_SERVICE_REQUEST
      });
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: localStorage.getItem("token"),
        }
      };
      const { data } = await axios.put(
        `${siteConfig.base_URL}/api/v1/service/${id}`, 
        service,
        config
      );
      dispatch({ type: UPDATE_SERVICE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: UPDATE_SERVICE_FAIL, payload: error.message });
    }
  };
  