
import { Box, Grid, Typography, Button, Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowForward, ArrowBack, Favorite } from "@mui/icons-material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { motion } from 'framer-motion';
import { styled } from '@mui/system';
import { perticularSubCetegory } from "../../../../action/subCategory";

const AllCategory = () => {
  const dispatch = useDispatch();
  const [subcategory, setSubcategory] = useState('');
  const { fetchAllSubCategory } = useSelector((state) => state.fetchAllSubCategory);

  useEffect(() => {
    dispatch(perticularSubCetegory("6662c6377814881072725b5e"));
  }, [dispatch]);

  useEffect(() => {
    if (fetchAllSubCategory) setSubcategory(fetchAllSubCategory);
  }, [fetchAllSubCategory]);



  const CustomArrow = ({ onClick, direction }) => (
    <ArrowButton onClick={onClick} style={{ [direction]: 0 }}>
      {direction === 'right' ? <ArrowForward /> : <ArrowBack />}
    </ArrowButton>
  );

  return (
    <Box style={{ marginTop: "0%" }} className="doorplusAllcatmt">
      <Box style={{ padding: "10px", position: "relative", overflow: "hidden" }} className="mediaAllcatBoxbpd">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <motion.div variants={containerVariants} initial="hidden" animate="visible">
              <Carousel responsive={responsive} autoPlay={false} autoPlaySpeed={3000} infinite={true} customRightArrow={<CustomArrow direction="right" />} customLeftArrow={<CustomArrow direction="left" />}>
                {(subcategory?.getsubcategory || []).map((service) => (
                  <ServiceCard key={service._id} className="mobilecssTopsaloon" variants={cardVariants}>
                    <ImageContainer>
                      <motion.img src={service.image?.url} alt={service.SubcategoryTitle} style={imageStyle} className='topsaloonimg-mb'  />
                      <IconOverlay>
                        <Favorite style={{ color: 'white', fontSize: '0.9rem' }} />
                      </IconOverlay>
                    </ImageContainer>
                    <Box sx={{ mt: 0.7 ,px:0.4 }}>
                      <Typography sx={{ my: 0.5, fontWeight: 600, fontSize: '0.7rem', color: 'grey' }}>{service.SubcategoryTitle}</Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: { xs: 1, sm: 0 } }}>
                          <Rating name="read-only" value={4.5} precision={0.5} readOnly size="small" sx={{ fontSize: '0.8rem' }} />
                          <Typography variant="body2" sx={{ marginLeft: '4px', fontSize: '0.8rem', fontWeight: 500 }}>4.5</Typography>
                        </Box>
                        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                          <Button size="small" sx={{
                            background: 'linear-gradient(135deg, rgb(107, 115, 255) 0%, rgb(0, 13, 255) 100%)',
                            textTransform: 'capitalize',
                            fontSize: '0.7rem',
                            padding: '3px 8px',
                            color: 'white',
                            borderRadius: '5px',
                            minWidth: 'auto',
                            '&:hover': {
                              background: 'linear-gradient(135deg, rgb(0, 13, 255) 0%, rgb(107, 115, 255) 100%)',
                            }
                          }}>Book + </Button>
                        </motion.div>
                      </Box>
                    </Box>
                  </ServiceCard>
                ))}
              </Carousel>
            </motion.div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};


export default AllCategory;

const imageStyle = { width: '100%', height: { xs: 120, sm: 150 }, borderRadius: 14 };
const responsive = {
  superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 3 },
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 5 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 3 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 2 },
};
const ArrowButton = styled(Button)(({ theme }) => ({
  position: 'absolute', top: '50%', transform: 'translateY(-50%)', backgroundColor: 'white',
  color: '#000DFF', minWidth: '30px', width: '30px', height: '30px', borderRadius: '50%',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  '&:hover': { backgroundColor: 'white', boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)' }
}));
const ServiceCard = styled(motion.div)(({ theme }) => ({
  borderRadius: 25, margin: '10px', padding: theme.spacing(2.5), boxShadow: '0 10px 30px #c5cae9',
  background: 'rgba(255, 255, 255, 0.95)', backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.3)', marginBottom: theme.spacing(2),
  perspective: '1000px', transformStyle: 'preserve-3d', transition: 'all 0.3s ease',
  display: 'flex', flexDirection: 'column',
  '&:hover': {
    transform: 'translateY(-5px) rotateX(5deg) rotateY(5deg)',
    boxShadow: '0 15px 30px rgba(197, 202, 233, 0.3)',
  },
}));
const ImageContainer = styled(Box)({ position: 'relative', overflow: 'hidden' });
const IconOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute', top: 8, right: 8, backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '50%', padding: 6, display: 'flex', alignItems: 'center',
  justifyContent: 'center', zIndex: 1, boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.3)',
  transition: 'all 0.3s ease',
  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)', transform: 'scale(1.1)' }
}));
const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { staggerChildren: 0.1 } }
};

const cardVariants = {
  hidden: { y: 50, opacity: 0 },
  visible: { y: 0, opacity: 1, transition: { type: "spring", damping: 12, stiffness: 100 } }
};
