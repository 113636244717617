import React, { useState } from 'react';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Collapse, Divider, Typography, Tooltip, Popover, alpha } from '@mui/material';
import { Home, Category, BusinessCenter, WebAsset, BookOnline, ExpandLess, ExpandMore } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAppStore } from '../appstore';
import Service from '../Services/service';

const drawerWidth = 250;

const Sidebar = styled(Drawer)(({ theme, open }) => ({
  width: drawerWidth,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      borderRight: '1px solid rgba(66, 42, 251, 0.12)',
      overflowX: 'hidden', 
    },
  }),
  ...(!open && {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(9),
    '& .MuiDrawer-paper': {
      width: theme.spacing(9),
      borderRight: '1px solid rgba(66, 42, 251, 0.08)',
      overflowX: 'hidden', 
    },
  }),
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme, open }) => ({
  minWidth: 0,
  marginRight: open ? theme.spacing(3) : 'auto',
  marginLeft: open ? 0 : 'auto',
  justifyContent: 'center',
  color: '#422afb',
  backgroundColor: alpha('#fff', 0.95),
  borderRadius: '10px',
  padding: '8px',
  boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
  
  '& svg': {
    fontSize: '1.5rem',
    transition: 'transform 0.2s, color 0.2s',
  },
}));

const StyledListItemButton = styled(ListItemButton)(({ theme, open }) => ({
  borderRadius: '8px',
  margin: '2px 8px',
  marginLeft:"17px",
  padding: open ? '8px 16px' : '8px 0',
  transition: 'all 0.2s',
  justifyContent: open ? 'flex-start' : 'center',
  '&:hover': {
    backgroundColor: 'rgba(66, 42, 251, 0.04)',
    '& .MuiListItemIcon-root svg': {
      transform: 'scale(1.1)',
      color: theme.palette.primary.dark,
    },
  },
  '&.Mui-selected': {
    backgroundColor: 'rgba(66, 42, 251, 0.08)',
    '& .MuiListItemIcon-root svg': {
      color: theme.palette.primary.dark,
    },
  },
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '12px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(66, 42, 251, 0.1)',
    overflow: 'hidden',
  },
}));

const theme = createTheme({
  palette: {
    primary: { main: '#422afb', dark: '#3219db' },
    background: { default: '#f0f2f5', paper: '#ffffff' },
    text: { primary: '#2B3674', secondary: '#7C8DB5' },
  },
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
});

export default function SidebarComponent() {
  const navigate = useNavigate();
  const open = useAppStore((state) => state.dopen);
  const [menuState, setMenuState] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeItem, setActiveItem] = useState(null);

  const handleMenuClick = (key, event) => {
    if (!open) {
      setAnchorEl(event.currentTarget);
      setActiveItem(key);
    } else {
      setMenuState(prev => ({ ...prev, [key]: !prev[key] }));
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setActiveItem(null);
  };

  const menuItems = [
    { key: 'home', text: 'Home', icon: <Home />, onClick: () => navigate("/") },
    {
      key: 'category', text: 'Category', icon: <Category />,
      subItems: [
        { text: 'Main Category', onClick: () => navigate("/Category") },
        { text: 'Sub Category', onClick: () => navigate("/SubCategory") },
      ],
    },
     { key: 'service', text: 'Service', icon: <Home />, onClick: () => navigate("/service") },
    {
      key: 'company', text: 'Company', icon: <BusinessCenter />,
      subItems: [
        { text: 'Blogs', onClick: () => navigate("/blog") },
        { text: 'Privacy Policy', onClick: () => navigate("/PrivacyPolicy") },
        { text: 'FAQ', onClick: () => navigate("/Faq") },
      ],
    },
    {
      key: 'landing', text: 'Landing', icon: <WebAsset />,
      subItems: [
        { text: 'Landing Banner', onClick: () => navigate("/LandingBanner") },
      ],
    },
    {
      key: 'booking', text: 'Bookings', icon: <BookOnline />,
      subItems: [
        { text: 'Booking List', onClick: () => navigate("/UserBooking") },
      ],
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Sidebar variant="permanent" open={open}>
        <Box sx={{ mt: 8 }} />
        <List>
          {menuItems.map((item) => (
            <React.Fragment key={item.key}>
              <ListItem disablePadding>
                <Tooltip title={open ? '' : item.text} placement="right">
                  <StyledListItemButton
                    onClick={(e) => item.subItems ? handleMenuClick(item.key, e) : item.onClick()}
                    selected={menuState[item.key]}
                    open={open}
                  >
                    <StyledListItemIcon open={open}>
                      {item.icon}
                    </StyledListItemIcon>
                    <ListItemText
                      primary={<Typography variant="subtitle2">{item.text}</Typography>}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                    {item.subItems && open && (
                      menuState[item.key] ? <ExpandLess /> : <ExpandMore />
                    )}
                  </StyledListItemButton>
                </Tooltip>
              </ListItem>
              {item.subItems && open && (
                <Collapse in={menuState[item.key]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subItems.map((subItem) => (
                      <ListItemButton
                        key={subItem.text}
                        sx={{ pl: 4, py: 0.5 }}
                        onClick={subItem.onClick}
                      >
                        <ListItemText
                          primary={<Typography variant="body2" color="text.secondary">{subItem.text}</Typography>}
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
              <Divider sx={{ my: 0.5, borderBottom: '1px solid rgba(66, 42, 251, 0.08)' }} />
            </React.Fragment>
          ))}
        </List>
      </Sidebar>
      <StyledPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        {activeItem && (
          <List sx={{ p: 1 }}>
            {menuItems.find(item => item.key === activeItem).subItems.map((subItem) => (
              <ListItemButton
                key={subItem.text}
                onClick={() => {
                  subItem.onClick();
                  handlePopoverClose();
                }}
                sx={{
                  borderRadius: '6px',
                  mb: 0.5,
                  '&:hover': {
                    backgroundColor: 'rgba(66, 42, 251, 0.04)',
                  },
                }}
              >
                <ListItemText 
                  primary={
                    <Typography variant="body2" color="text.primary">
                      {subItem.text}
                    </Typography>
                  } 
                />
              </ListItemButton>
            ))}
          </List>
        )}
      </StyledPopover>
    </ThemeProvider>
  );
}