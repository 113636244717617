import React from 'react';
import {  Grid,Box, Typography, Button, } from "@mui/material";
import { useParams, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import 'react-toastify/dist/ReactToastify.css';
import { Add } from '@mui/icons-material';
import Cart from '../../inc/CartItem';
import { addToCart } from '../../../../action/cartAction';
import { useDispatch, useSelector } from "react-redux";
import OtherCat from '../../inc/otherCat';
import {ToastContainer } from 'react-toastify';
import Footer from '../../inc/Footer/footer';


const PremiumButton = styled(Button)(({ theme }) => ({
    marginTop:"20px",
    background: 'linear-gradient(135deg, #000DFF 0%, #6B73FF 100%)',
    color: '#FFFFFF',
    fontWeight: 600,
    borderRadius: 6,
    padding: '6px 16px',
    border: '3px solid white',
    boxShadow: '0 8px 25px rgba(0, 13, 255, 0.1)',
    fontSize: '1rem',
    textTransform: 'none',
    '&:hover': {
      background: 'linear-gradient(135deg, #0009CC 0%, #5158CC 100%)'
    },
    '&:disabled': {
      background: '#CCCCCC',
      color: '#666666',
    },
  }));
const ServiceDetails = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const cartItem = useSelector(state => state.cart);
    const { serviceData } = location.state || {};
    console.log(serviceData,"serviceData")
    const isInCart = (itemId) => {
        return cartItem?.cart?.services?.some(item => item?.serviceId?._id === itemId._id);
      };
      const handleBookService = (service) => {
        
        dispatch(addToCart(service));
       
      };
    
  return (
    <>
    <Box sx={{ marginTop:"90px", marginLeft:"50px", marginRight:"50px" }}>
      <Grid container spacing={4}>
      <Grid item xs={12} sm={3}>
        <OtherCat />
        </Grid>
        <Grid item xs={6}>
    <Box>
        <Grid container spacing={4}>
            <Grid item xs={5}>
            <img src={serviceData.image?.url} style={{width:"300px", borderRadius:"10px"}} />
            </Grid>
            <Grid item xs={7}>
           <Box sx={{padding:"30px"}}>
           <Typography variant="h3" sx={{color:"#000DFF", fontSize:"18px",lineHeight:"30px" }}>
          {serviceData.name}
        </Typography>
        <Typography variant="h6">
          <span style={{color:"#000", fontSize:"12px"}}>Service Price:</span> <span style={{color:"#000DFF", fontSize:"14px"}}>₹{serviceData.price.basePrice}</span>
        </Typography>
        <Typography variant="h6">
        <span style={{color:"#000", fontSize:"12px"}}>Duration:</span> <span style={{color:"#000DFF", fontSize:"14px"}}>{serviceData.duration} min</span>
        </Typography>
        {isInCart(serviceData) ? (<PremiumButton
          size="small"
          startIcon={ <Add />}
          disabled
        >Added
        </PremiumButton>) : (<PremiumButton
        size="small"
        onClick={() => handleBookService(serviceData)}
      >
        { 'Book Now'}
      </PremiumButton>)}
           </Box>
            </Grid>
        </Grid>
        <Typography variant="body1" color="textSecondary" sx={{ my: 1 }}>
          {serviceData.description}
        </Typography>
        
    </Box>
        </Grid>
        <Grid
          item
          xs={3}
        >
          <Cart />
        </Grid>
      </Grid>
      <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
    </Box>
    <Footer />
</>
  );
};

export default ServiceDetails;
