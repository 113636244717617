import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import { IoHomeOutline } from "react-icons/io5";
import { MdOutlineSupportAgent } from "react-icons/md";
import { BsStars } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

function MobileBottomNavigation() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedIcon, setSelectedIcon] = useState("Home");
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path === "/") setSelectedIcon("Home");
    else if (path.includes("/Booking")) setSelectedIcon("My Bookings");
    else if (path.includes("/help")) setSelectedIcon("Need Help?");
  }, [location]);

  if (!isMobile) return null;

  const handleIconClick = (icon) => setSelectedIcon(icon);

  const navItems = [
    { icon: IoHomeOutline, label: "Home", to: "/" },
    { icon: BsStars, label: "My Bookings", to: "/BookingHistory" },
    { icon: MdOutlineSupportAgent, label: "Need Help?", to: "" },
    
  ];

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: -7,
        left: 0,
        right: 0,
        height: 100,
        backgroundColor: "transparent",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        padding: "0 16px 16px",
        pointerEvents: "none",
        zIndex: 1000,
      }}
    >
      <motion.div
        // initial={{ y: 100 }}
        // animate={{ y: 0 }}
        // transition={{ type: "spring", stiffness: 500, damping: 30 }}
        style={{
          display: "flex",
          width: "100%",
          maxWidth: 600,
          backgroundColor: "#ffffffc7",
      //    background: 'rgba(255, 255, 255, 0.25)',
          backdropFilter: 'blur(4px)',
          border: '2px solid rgba(255, 255, 255, 0.18)',
          borderRadius: 15,
          boxShadow: "0 10px 40px rgba(0, 0, 0, 0.2)",
          padding: "12px 27px",
          pointerEvents: "auto",
        }}
      >
        {navItems.map((item, index) => (
          <Link
            key={item.label}
            to={item.to}
            style={{ textDecoration: "none", color: "inherit", flex: 1 }}
            onClick={() => handleIconClick(item.label)}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: 50,
              }}
            >
              <AnimatePresence>
                {selectedIcon === item.label && (
                  <motion.div
                    layoutId="bubble"
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                    transition={{ type: "spring", stiffness: 500, damping: 60 }}
                    style={{
                      position: "absolute",
                      top: -0,
                      left: -0,
                      right: -0,
                      bottom: -0,
                      backgroundColor: "rgba(0, 127, 255, 0.1)",
                      borderRadius: 10,
                      zIndex: -1,
                    }}
                  />
                )}
              </AnimatePresence>
              <motion.div
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
              >
                <item.icon
                  style={{
                    fontSize: 17,
                    color: selectedIcon === item.label ? "#6B73FF" : "#555",
                    transition: "color 0.3s ease",
                  }}
                />
              </motion.div>
              <motion.span
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2 }}
                style={{
                  fontSize: 10,
                  fontWeight: selectedIcon === item.label ? 600 : 400,
                  color: selectedIcon === item.label ? "#6B73FF" : "#555",
                  marginTop: 8,
                }}
              >
                {item.label}
              </motion.span>
            </Box>
          </Link>
        ))}
      </motion.div>
    </Box>
  );
}

export default MobileBottomNavigation;