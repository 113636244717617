import React, { useState } from 'react';
import {
    Box, Grid, Card, Typography, Button, Badge, CardContent,
    InputLabel,
    Stack,
    TextField,
    Select,
    MenuItem,
    Dialog, DialogTitle, DialogContent, DialogActions,
    Radio, RadioGroup, FormControlLabel, FormControl
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import 'react-toastify/dist/ReactToastify.css';
import { Add, ShoppingCart } from '@mui/icons-material';
import Cart from '../../inc/CartItem';
import { addToCart } from '../../../../action/cartAction';
import { useDispatch, useSelector } from "react-redux";
import OtherCat from '../../inc/otherCat';
import Footer from '../../inc/Footer/footer';
import axios from "axios";
import {ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import siteConfig from "../../../../siteConfig";


const PremiumButton = styled(Button)(({ theme }) => ({
    marginTop: "20px",
    background: 'linear-gradient(135deg, #000DFF 0%, #6B73FF 100%)',
    color: '#FFFFFF',
    fontWeight: 600,
    borderRadius: 6,
    padding: '6px 16px',
    border: '3px solid white',
    boxShadow: '0 8px 25px rgba(0, 13, 255, 0.1)',
    fontSize: '1rem',
    textTransform: 'none',
    '&:hover': {
        background: 'linear-gradient(135deg, #0009CC 0%, #5158CC 100%)'
    },
    '&:disabled': {
        background: '#CCCCCC',
        color: '#666666',
    },
}));
const Checkout = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cartItem = useSelector(state => state.cart);
    console.log(cartItem,"cart?.services")
    const [bookService, setBookbookService] = useState({
        name: "",
        date: "",
        time: "",
        mobileNo: "",
        city: "Kanpur",
        pinCode: "",
        address: "",
        payment:"pay_on_service",
        cartItem:cartItem?.cart?.services
    });
    const [openModal, setOpenModal] = useState(false);

    const handleProcessClick = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "mobileNo" && !/^\d*$/.test(value)) return;
        if (name === "pinCode" && !/^\d*\.?\d*$/.test(value)) return;
        setBookbookService((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };


const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(bookService,"hhhhhhhh")
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(`${siteConfig.base_URL}/api/v1/UserBookService`, bookService,{
        headers: {
          "token": token
        }
      });
      if (response.data.message) {
        toast.success(response.data.message);
       setOpenModal(false)
       navigate("/Success")
      } else {
        toast.error(response);
      }
    } catch (error) {
      toast.error(error.response?.data?.message);
    }
  };
    return (
        <>
            <Box sx={{ marginTop: "120px", marginLeft: "50px", marginRight: "50px" }}>
                <Grid container spacing={4}>
                    <Grid item xs={9}>
                    <StyledCard sx={{ mb: 3, overflow: 'hidden', position: 'relative' }}>
                        <Box sx={{padding:"40px"}}>
                            <Grid container spacing={2}>
                                <Grid item xs={4} style={{ marginBottom: "5px" }}>
                                    <Stack spacing={1}>
                                        <InputLabel
                                            htmlFor="bookService Name"
                                            style={{ marginBottom: "5px", fontSize: "12px", color: "#422afb" }}
                                        >
                                            Name<span style={{ color: "#f44336" }}>*</span>
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            placeholder="Name"
                                            id="categoryTitle"
                                            name="name"
                                            variant="outlined"
                                            value={bookService.name}
                                            onChange={handleChange}
                                            InputProps={{
                                                style: {
                                                    borderColor: "#422afb",
                                                    padding: "10px 0px",
                                                    fontSize: '12px'
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: "12px" },
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                    <Stack spacing={1}>
                                        <InputLabel
                                            htmlFor="Date"
                                            style={{ marginBottom: "5px", fontSize: "12px", color: "#422afb" }}
                                        >
                                            Date<span style={{ color: "#f44336" }}>*</span>
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            placeholder="Date"
                                            id="categoryTitle"
                                            name="date"
                                            type="date"
                                            variant="outlined"
                                            value={bookService.date}
                                            onChange={handleChange}
                                            InputProps={{
                                                style: {
                                                    borderColor: "#422afb",
                                                    padding: "10px 0px",
                                                    fontSize: '12px'
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: "12px" },
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                    <Stack spacing={1}>
                                        <InputLabel
                                            htmlFor="time"
                                            style={{ marginBottom: "5px", fontSize: "12px", color: "#422afb" }}
                                        >
                                            Time<span style={{ color: "#f44336" }}>*</span>
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            label="time"
                                            id="category"
                                            name="time"
                                            variant="outlined"
                                            value={bookService.time}
                                            onChange={handleChange}

                                            displayEmpty
                                            inputProps={{
                                                style: { borderColor: "#c4c4c4" },
                                            }}
                                        >

                                            <MenuItem value="">
                                                Select Time
                                            </MenuItem>
                                            <MenuItem value="09:00 am-11:00 am" key="09:00 am-11:00 am">{"09:00 am-11:00 am"}</MenuItem>
                                            <MenuItem value="11:00 am-01:00 pm" key="11:00 am-01:00 pm">{"11:00 am-01:00 pm"}</MenuItem>
                                            <MenuItem value="01:00 pm-03:00 pm" key="01:00 pm-03:00 pm">{"01:00 pm-03:00 pm"}</MenuItem>
                                            <MenuItem value="03:00 pm-05:00 pm" key="03:00 pm-05:00 pm">{"03:00 pm-05:00 pm"}</MenuItem>
                                            <MenuItem value="06:00 pm-07:00 pm" key="06:00 pm-07:00 pm">{"06:00 pm-07:00 pm"}</MenuItem>

                                        </Select>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4} style={{ marginBottom: "5px" }}>
                                    <Stack spacing={1}>
                                        <InputLabel
                                            htmlFor="MobileNo"
                                            style={{ marginBottom: "5px", fontSize: "12px", color: "#422afb" }}
                                        >
                                            Mobile No<span style={{ color: "#f44336" }}>*</span>
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            placeholder="MobileNo"
                                            id="MobileNo"
                                            name="mobileNo"
                                            variant="outlined"
                                            value={bookService.mobileNo}
                                            onChange={handleChange}
                                            InputProps={{
                                                style: {
                                                    borderColor: "#422afb",
                                                    padding: "10px 0px",
                                                    fontSize: '12px'
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: "12px" },
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={4} style={{ marginBottom: "5px" }}>
                                    <Stack spacing={1}>
                                        <InputLabel
                                            htmlFor="City"
                                            style={{ marginBottom: "5px", fontSize: "12px", color: "#422afb" }}
                                        >
                                            City <span style={{ color: "#f44336" }}>*</span>
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            placeholder="City"
                                            id="Duration"
                                            name="city"
                                            variant="outlined"
                                            value={bookService.city}
                                            onChange={handleChange}
                                            InputProps={{
                                                style: {
                                                    borderColor: "#422afb",
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: "12px" },
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={4} style={{ marginBottom: "5px" }}>
                                    <Stack spacing={1}>
                                        <InputLabel
                                            htmlFor="PinCode"
                                            style={{ marginBottom: "5px", fontSize: "12px", color: "#422afb" }}
                                        >
                                            PinCode <span style={{ color: "#f44336" }}>*</span>
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            placeholder="PinCode"
                                            id="PinCode"
                                            name="pinCode"
                                            variant="outlined"
                                            value={bookService.pinCode}
                                            onChange={handleChange}
                                            InputProps={{
                                                style: {
                                                    borderColor: "#422afb",
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: "12px" },
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={1}>
                                        <InputLabel
                                            htmlFor="address"
                                            style={{ marginBottom: "5px", fontSize: "12px", color: "#422afb" }}
                                        >
                                            Address<span style={{ color: "#f44336" }}>*</span>
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            placeholder="Address"
                                            id="Address"
                                            name="address"
                                            variant="outlined"
                                            value={bookService.address}
                                            onChange={handleChange}
                                            InputProps={{
                                                style: {
                                                    borderColor: "#422afb",
                                                    padding: "10px 0px",
                                                    fontSize: "12px",
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: "12px" },
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                <PremiumButton onClick={handleProcessClick} size="small" sx={{ mt: 1 }}>Process</PremiumButton>
                                </Grid>
                            </Grid>

                        </Box>
                        </StyledCard>
                    </Grid>
                    <Grid
                        item
                        xs={3}
                    >
                        <StyledCard sx={{ mb: 3, overflow: 'hidden', position: 'relative' }}>
                            <Box sx={{ background: 'linear-gradient(135deg, #000DFF 0%, #6B73FF 100%)', p: 2, textAlign: 'center', borderRadius: '12px 12px 50% 50% / 12px 12px 30px 30px' }}>
                                <Badge color="error" sx={{ '& .MuiBadge-badge': { top: 5, right: 5 } }}>
                                    <ShoppingCart sx={{ fontSize: 36, color: '#FFFFFF' }} />
                                </Badge>
                                <Typography variant="h6" sx={{ mt: 1, fontWeight: 700, color: '#FFFFFF' }}>Your Cart</Typography>
                            </Box>
                            <CardContent sx={{ p: 2 }}>
                                <>
                                    {cartItem?.cart?.services?.map((item) => (
                                        <Box key={item._id} sx={{ display: 'flex', alignItems: 'center', mb: 2, p: 1, borderRadius: 2, background: 'rgba(0, 13, 255, 0.05)' }}>
                                            <Box component="img" sx={{ width: 40, height: 40, borderRadius: 1, mr: 2 }} src={item?.serviceId?.image?.url} alt='img' />
                                            <Box sx={{ flexGrow: 1 }}>
                                                <Typography variant="body2" sx={{ fontWeight: 600 }}>{item?.serviceId?.name}</Typography>
                                                <Typography variant="caption">₹{item?.serviceId?.price.basePrice}</Typography>
                                            </Box>
                                        </Box>
                                    ))}
                                   
                                </>
                            </CardContent>
                        </StyledCard>

                        


                        <StyledDialog open={openModal} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: "center", fontWeight: 700 }}>
        Choose Payment Method
      </DialogTitle>
      <DialogContent>
        <RadioGroup name="payment" value={bookService.payment} onChange={handleChange}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 2,
              borderRadius: "10px",
              border: bookService.payment === "pay_on_service" ? "2px solid #000DFF" : "2px solid transparent",
              transition: "0.3s",
              "&:hover": { border: "2px solid #6B73FF" },
            }}
          >
            <FormControlLabel
              value="pay_on_service"
              control={<Radio />}
              label={<Typography fontWeight={600}>Pay on Service</Typography>}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 2,
              borderRadius: "10px",
              border: bookService.payment === "pay_then_submit" ? "2px solid #000DFF" : "2px solid transparent",
              transition: "0.3s",
              "&:hover": { border: "2px solid #6B73FF" },
              mt: 2,
            }}
          >
            <FormControlLabel
              value="pay_online"
              disabled
              control={<Radio />}
              label={<Typography fontWeight={600}>Online Pay</Typography>}
            />
          </Box>
        </RadioGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", pb: 2 }}>
        <Button
          variant="contained"
          sx={{
            background: "linear-gradient(135deg, #000DFF 0%, #6B73FF 100%)",
            color: "#FFF",
            fontWeight: 600,
            textTransform: "none",
            borderRadius: "6px",
            px: 3,
          }}
          onClick={handleSubmit}
        >
          Confirm
        </Button>
      </DialogActions>
    </StyledDialog>
                    </Grid>
                </Grid>
                <ToastContainer
                    position="top-right"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </Box>
            <Footer />
        </>
    );
};
const StyledCard = styled(Card)(({ theme }) => ({
    background: 'rgba(255, 255, 255, 0.25)',
    borderRadius: '12px',
    backdropFilter: 'blur(5px)',
    boxShadow: '0 8px 25px rgba(0, 13, 255, 0.1)',
    border: '4px solid white',
    transition: 'all 0.3s ease',
    '&:hover': {
        transform: 'translateY(-3px)',
        boxShadow: '0 8px 25px rgba(0, 13, 255, 0.1)'
    },
}));
const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: "12px",
      padding: "20px",
    },
  }));
export default Checkout;
