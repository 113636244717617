import React, { useEffect, useState } from "react";
import {
  Box, Grid, Typography, Dialog, DialogTitle, DialogContent, DialogActions,
  Button, IconButton, Tooltip, alpha, ThemeProvider, createTheme, Divider
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { styled } from '@mui/system';
import { deleteBlogs, getAllBlogs } from '../../action/blogAction';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CloseIcon from '@mui/icons-material/Close';



const BlogPreview = () => {
  const [open, setOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const { getBlogs } = useSelector((state) => state.getBlogs);
  const dispatch = useDispatch();
  const [BlogDelete, setBlogDelete] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    dispatch(getAllBlogs());
  }, [dispatch]);

  const handleOpen = (post) => {
    console.log(post, "jjhh")
    setSelectedPost(post);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const formatDate = (date) => {
    const formattedDate = new Date(date).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour12: true
    });
    return formattedDate;
  };
  const handleDelete = (_id) => {
    setOpenDeleteDialog(true);
    setBlogDelete(_id);
  };
  const handleConfirmDelete = async () => {
    setOpenDeleteDialog(false);
    await dispatch(deleteBlogs(BlogDelete));
    dispatch(getAllBlogs());
    toast.error("Blog deleted successfully");
  };
  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
  };


  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ p: 1, mt: 1 }}>
        <Grid container spacing={1}>
          {getBlogs?.data?.map((post) => (
            <Grid item xs={6} sm={4} md={3} lg={2} key={post._id}>
              <Box sx={webStyle.ServiceCard}>
                <Box sx={webStyle.CardContent}>
                  <img src={post.blogPost.image} alt={post.title} style={webStyle.image} className='topsaloonimg-mb' />
                  <Typography variant="subtitle2" sx={webStyle.title}>{post.blogPost.title.substring(0, 20)}...</Typography>
                </Box>
                <Box sx={webStyle.CardActions}>
                  <Box sx={webStyle.ActionsLeft}>
                    <Tooltip title="Edit" arrow placement="top">
                      <MiniButton size="small"><EditOutlinedIcon sx={{ fontSize: 14, color: '#4CAF50' }} /></MiniButton>
                    </Tooltip>
                    <Tooltip title="Delete" arrow placement="top">
                      <MiniButton size="small" onClick={() => handleDelete(post._id)}><DeleteOutlineOutlinedIcon sx={{ fontSize: 14, color: '#F44336' }} /></MiniButton>
                    </Tooltip>
                  </Box>
                  <Box sx={webStyle.ActionsRight}>
                    <Tooltip title="View" arrow placement="top">
                      <MiniButton size="small" onClick={() => handleOpen(post)}><VisibilityOutlinedIcon sx={{ fontSize: 14, color: '#422afb' }} /></MiniButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      </Box>
      {/* // =========================================== Delete Model ========================================= // */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}
        PaperProps={{ sx: { borderRadius: 2, background: alpha(theme.palette.background.paper, 0.9), backdropFilter: 'blur(5px)', width: '100%', maxWidth: 350 } }}>
        <DialogTitle sx={{ bgcolor: alpha(theme.palette.error.main, 0.1), color: 'error.main', py: 2, fontSize: '0.9rem', fontWeight: 600 }}>
          Confirm Delete
        </DialogTitle>
        <DialogContent sx={{ py: 2, mt: 2, }}>
          <Typography variant="body2" fontSize="0.8rem">Delete this post?</Typography>
        </DialogContent>
        <DialogActions sx={{ px: 2, pb: 1 }}>
          <Button onClick={() => setOpenDeleteDialog(false)} variant="outlined" size="small" sx={{ borderRadius: 1, fontSize: '0.7rem' }}>Cancel</Button>
          <Button onClick={handleConfirmDelete} variant="contained" color="error" size="small" sx={{ borderRadius: 1, fontSize: '0.7rem' }}>Delete</Button>
        </DialogActions>
      </Dialog>

      {/* // =========================================== view Model ========================================= // */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" PaperProps={{ style: { borderRadius: 12, boxShadow: '0 16px 32px rgba(0, 0, 0, 0.2)', overflow: 'hidden', backgroundColor: '#ffffff' } }}>
        {selectedPost && (
          <>
            <DialogTitle style={{ fontSize: '20px', fontWeight: 600, padding: '16px 24px', background: 'linear-gradient(90deg, #422afb, #4e54f7)', color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
              {selectedPost.blogPost.title}
              <IconButton onClick={handleClose} style={{ color: 'white' }}><CloseIcon /></IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: '16px 24px', backgroundColor: 'rgba(66, 42, 251, 0.05)', maxHeight: '70vh', overflowY: 'auto', boxSizing: 'border-box', scrollbarWidth: 'thin', scrollbarColor: '#422afb rgba(66, 42, 251, 0.08)' }}>
              <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
                <Box flexBasis={{ xs: '100%', md: '40%' }}>
                  <img src={selectedPost.blogPost.image} alt="" style={{ width: '100%', height: '200px', objectFit: 'cover', borderRadius: 8, boxShadow: '0 6px 18px rgba(0, 0, 0, 0.15)' }} />
                  <Typography variant="caption" style={{ color: '#777' }}>Published on:{formatDate(selectedPost.blogPost.created_at)}</Typography>
                </Box>
                <Box flexBasis={{ xs: '100%', md: '60%' }}>
                  <Typography variant="body1" style={{ fontSize: '16px', marginBottom: '16px', textAlign: 'justify', lineHeight: 1.6, color: '#333' }}>
                    <div dangerouslySetInnerHTML={{ __html: selectedPost.blogPost.description }} />
                  </Typography>
                  <Divider style={{ margin: '16px 0', borderColor: '#e0e0e0' }} />
                
                </Box>
              </Box>
            </DialogContent>
            <DialogActions style={{ padding: '12px 24px', borderTop: '1px solid rgba(0, 0, 0, 0.1)' }}>
              <Button onClick={handleCancelDelete} variant="contained" style={{ backgroundColor: '#422afb', color: 'white', borderRadius: '20px', padding: '10px 20px', textTransform: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', fontWeight: 600, fontSize: '14px' }}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </ThemeProvider>
  );
};

export default BlogPreview;



// =========================================== Style Css ========================================= //

const theme = createTheme({
  palette: { primary: { main: '#000DFF' }, secondary: { main: '#6B73FF' } },
  typography: { fontFamily: 'Inter, sans-serif' }, shape: { borderRadius: 8 }
});

const webStyle = {
  ServiceCard: {
    borderRadius: '10px', boxShadow: '0 4px 20px rgba(66, 42, 251, 0.08)',
    border: '1px solid rgba(66, 42, 251, 0.12)', transition: 'all 0.3s ease',
    display: 'flex', flexDirection: 'column', height: '100%',
    '&:hover': { transform: 'translateY(-3px)', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.08)' },
  },
  CardContent: { flexGrow: 1, padding: '8px', display: 'flex', flexDirection: 'column' },
  CardActions: { display: 'flex', justifyContent: 'space-between', padding: '8px', borderTop: '1px solid rgba(0, 0, 0, 0.12)' },
  image: { width: '100%', height: 100, borderRadius: 6, objectFit: 'cover', marginBottom: '8px' },
  title: { fontWeight: 600, fontSize: '0.7rem', color: 'text.primary', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' },
  ActionsLeft: { display: 'flex', gap: '8px' }, ActionsRight: { display: 'flex', gap: '8px' }
};

const MiniButton = styled(IconButton)(({ theme }) => ({
  background: 'rgba(66, 42, 251, 0.08)', backdropFilter: 'blur(4px)', boxShadow: '0 1px 4px rgba(0, 0, 0, 0.08)',
  borderRadius: theme.shape.borderRadius, padding: 4, transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  '&:hover': { transform: 'scale(1.05)', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.12)' },
}));
