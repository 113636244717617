import React, { useEffect, useState } from "react";
import { Table, TableHead, TableBody, TableRow, TableCell, Paper, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deleteCategory, getAllService, deleteService } from "../../action/serviceAction";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SearchBar from "../sidebar/searchbar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllBooking } from "../../action/UserServiceBookingAction";


const ServiceList = ({ handleEdit,handleData }) => {
    const dispatch = useDispatch();
    const {getBookingData} = useSelector((state) => state.getBookingData);
    console.log(getBookingData,"getBookingData")
    const [bookingData, setBookingData] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [filteredServiceData, setFilteredServiceData] = useState([]);
    const [catDelete, setCatDelete] = useState(0);
    useEffect(() => {
        dispatch(getAllBooking());
    }, [dispatch, handleData]);
    useEffect(() => {
        if (getBookingData) {
            setBookingData(getBookingData.bookings);
            setFilteredServiceData(getBookingData.bookings);
        }
    }, [getBookingData]);

    const handleDelete = (_id) => {
        setOpenDeleteDialog(true);
        setCatDelete(_id);
    };

    const handleConfirmDelete = async () => {
        setOpenDeleteDialog(false);
        await dispatch(deleteService(catDelete));
        dispatch(getAllService());
        toast.error("Service deleted successfully");
    };

    const handleCancelDelete = () => {
        setOpenDeleteDialog(false);
    };


    const handleSearch = (query) => {
        const filteredData = bookingData.categories.filter(category => {

            const lowerCaseQuery = query.toLowerCase();
            return (
                category.title.toLowerCase().includes(lowerCaseQuery) ||
                category.description.toLowerCase().includes(lowerCaseQuery) ||
                formatDate(category.createdAt).toLowerCase().includes(lowerCaseQuery)
            );
        });
        setFilteredServiceData(filteredData);
    };
    const formatDate = (date) => {
        const formattedDate = new Date(date).toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour12: true
        });
        return formattedDate;
    };

    const statusColors = {
        pending: "#FFC107",     // Yellow
        confirmed: "#4CAF50",   // Green
        completed: "#2196F3",   // Blue
        cancelled: "#F44336"    // Red
      };

    return (
        <>
            <SearchBar onSearch={handleSearch} />
            <Paper style={{
                margin: "20px 0px",
                boxShadow: "rgba(17, 17, 26, 0.1) 0px 0px 16px"

            }}>
                <Table>
                    <TableHead sx={{ backgroundColor: 'rgba(66, 42, 251, 0.1)' }}>
                        <TableRow>
                            <TableCell sx={{ color: '#422afb', fontWeight: 'bold', fontSize: "11px", padding: '15px', borderBottom: '2px solid #422afb' }}>Serial Number</TableCell>
                            <TableCell sx={{ color: '#422afb', fontWeight: 'bold', fontSize: "11px", padding: '15px', borderBottom: '2px solid #422afb' }}>Name</TableCell>
                            <TableCell sx={{ color: '#422afb', fontWeight: 'bold', fontSize: "11px", padding: '15px', borderBottom: '2px solid #422afb' }}>Service Date</TableCell>
                            <TableCell sx={{ color: '#422afb', fontWeight: 'bold', fontSize: "11px", padding: '15px', borderBottom: '2px solid #422afb' }}>Timing</TableCell>
                            <TableCell sx={{ color: '#422afb', fontWeight: 'bold', fontSize: "11px", padding: '15px', borderBottom: '2px solid #422afb' }}>MobileNo</TableCell>
                            <TableCell sx={{ color: '#422afb', fontWeight: 'bold', fontSize: "11px", padding: '15px', borderBottom: '2px solid #422afb' }}>PinCode</TableCell>
                            <TableCell sx={{ color: '#422afb', fontWeight: 'bold', fontSize: "11px", padding: '15px', borderBottom: '2px solid #422afb' }}>Address</TableCell>
                            <TableCell sx={{ color: '#422afb', fontWeight: 'bold', fontSize: "11px", padding: '15px', borderBottom: '2px solid #422afb' }}>Payment</TableCell>
                            <TableCell sx={{ color: '#422afb', fontWeight: 'bold', fontSize: "11px", padding: '15px', borderBottom: '2px solid #422afb' }}>Status</TableCell>
                            <TableCell sx={{ color: '#422afb', fontWeight: 'bold', fontSize: "11px", padding: '15px', borderBottom: '2px solid #422afb' }}>created Date</TableCell>
                            <TableCell sx={{ color: '#422afb', fontWeight: 'bold', fontSize: "11px", padding: '15px', borderBottom: '2px solid #422afb' }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredServiceData?.map((row, index) => (
                            <TableRow key={row._id}
                                sx={{
                                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff',
                                    '&:hover': {
                                        backgroundColor: '#f1f1f1',
                                        cursor: 'pointer',
                                    },
                                    borderBottom: '1px solid #dbdbdb',
                                }}
                            >
                                <TableCell sx={{ textTransform: "capitalize", padding: '15px', fontSize: "11px" }}>{index + 1}</TableCell>
                                <TableCell sx={{ textTransform: "capitalize", padding: '15px', fontSize: "11px" }}>{row.name}</TableCell>
                                <TableCell sx={{ textTransform: "capitalize", padding: '15px', fontSize: "11px" }}>{row.date}</TableCell>
                                <TableCell sx={{ textTransform: "capitalize", padding: '15px', fontSize: "11px" }}>{row.time}</TableCell>
                                <TableCell sx={{ textTransform: "capitalize", padding: '15px', fontSize: "11px" }}>{row.mobileNo}</TableCell>
                                <TableCell sx={{ textTransform: "capitalize", padding: '15px', fontSize: "11px" }}>{row.pinCode}</TableCell>
                                <TableCell sx={{ textTransform: "capitalize", padding: '15px', fontSize: "11px" }}>{row.address}</TableCell>
                                <TableCell sx={{ textTransform: "capitalize", padding: '15px', fontSize: "11px" }}>{row.payment}</TableCell>
                                <TableCell sx={{ textTransform: "capitalize", padding: '15px', fontSize: "11px", color: statusColors[row.status] || "black" }}>{row.status}</TableCell>
                                <TableCell sx={{ textTransform: "capitalize", padding: '15px', fontSize: "11px" }}>{formatDate(row.createdAt)}</TableCell>
                                <TableCell>
                                    <IconButton aria-label="edit" style={{ color: 'green' }} onClick={() => handleEdit(row)}>
                                        <EditIcon style={{ fontSize: "19px" }} />
                                    </IconButton>
                                    <IconButton aria-label="delete" onClick={() => handleDelete(row._id)} style={{ color: 'red' }}>
                                        <DeleteIcon style={{ fontSize: "19px" }} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </Paper>
            <Dialog
                open={openDeleteDialog}
                onClose={handleCancelDelete}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle style={{ background: "#c6d7fd", color: '#422afb' }}>Delete Confirmation</DialogTitle>
                <DialogContent style={{ marginTop: "20px" }}>
                    Are you sure you want to delete this item?
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<ClearOutlinedIcon />} onClick={handleCancelDelete} style={{ backgroundColor: '#c6d7fd', color: '#422afb', fontSize: "12px", textTransform: "capitalize" }}>
                        Cancel
                    </Button>
                    <Button startIcon={<DeleteIcon />} onClick={handleConfirmDelete} style={{ backgroundColor: 'red', color: '#422afb', fontSize: "12px", textTransform: "capitalize" }}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            {/* <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            /> */}
        </>
    );
}

export default ServiceList;
