import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Login from "./components/user/Auth/LoginForm/Login.jsx";
import SignupForm from "./components/user/Auth/SignupForm/Signup.jsx";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "./action/UserAuthActions.js";
import Home from "../src/components/Home/Home.jsx";
import SidebarAdmin from "./Admin/sidebar/sidebar.jsx";
import ProtectedRoute from "./ProtectedRoute.js";
import FrequentlyQuestions from "./components/user/pages/frequentlyQuestions/frequentlyQuestions.jsx";
import TermsAndConditions from "./components/user/pages/TermsConditions/terms&Conditions.jsx";
import PrivacyAndPolicy from "./components/user/pages/privacyPage/privacy&Policy.jsx";
import Address from "./components/user/pages/addressBook/manageaddress.jsx";
import BookingHistory from "./components/user/pages/bookingHistory/BookingHistory.jsx";
import EditProfile from "./components/user/mobile/profle/editProfile/editProfile.jsx";
import ProfilePage from "./components/user/mobile/profle/profile.jsx";
import NeedHelp from "./components/user/mobile/needHelp/needHelp.jsx";
import AboutUs from "./components/user/pages/aboutUs/aboutus.jsx";
import Navigation from "./components/user/inc/navigationBar/navigation.jsx";
import Category from "./Admin/categoryMaster/category/category.jsx";
import SubCategory from "./Admin/categoryMaster/subCategory/subcategory.jsx";
import Service from "./Admin/Services/service.jsx";
import Homeadmin from "./Admin/Home/Home.jsx";
import Services from "./components/user/pages/Services/Services.jsx";
import ServiceDetails from "./components/user/pages/Services/Servicedetails.jsx";
import Blog from "./Admin/blogs/blog.jsx";
import Faq from "./Admin/Faq/Faq.jsx";
import LandingBanner from "./Admin/herobanner/landingBg.jsx";
import UserBooking from "./Admin/Bookings/Bookings.jsx";
import Checkout from "./components/user/pages/checkout/Checkout.jsx";
import Profile from "./components/user/pages/profile/profile.jsx";
import Chatbot from "../../frontend/src/components/Chatbot/Chatbot.js"
import PublicRoute from "./PublicRoute.js";
import SuccessFullPage from "./components/user/pages/success/Success.jsx";
function App() {
  const { user, isAuthenticated, loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isAuthenticated && !loading || !user) {
      dispatch(loadUser());
    }
  }, [dispatch, isAuthenticated, loading, user]);

  console.log(user, "useruseruser");

  return (
    <Router>
      {user?.role === "admin" ? <AdminRoutes user={user} /> : <UserRoutes user={user} />}
    </Router>
  );
}

function AdminRoutes() {
  const location = useLocation();
  const hideSidebar = location.pathname === "/login" || location.pathname === "/signup";
  return (
    <>
      
     {!hideSidebar && <SidebarAdmin />}
      <Routes>
      <Route path={"/"} element={<ProtectedRoute isAdmin={true} element={Homeadmin} />} />
        <Route path={"/admin"} element={<ProtectedRoute isAdmin={true} element={Homeadmin} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignupForm />} />
        <Route path="/Category" element={<ProtectedRoute isAdmin={true} element={Category} />} />
        <Route path="/SubCategory" element={<ProtectedRoute isAdmin={true} element={SubCategory} />} />
        <Route path="/Service" element={<ProtectedRoute isAdmin={true} element={Service} />} />
        <Route path="/Blog" element={<ProtectedRoute isAdmin={true} element={Blog} />} />
        <Route path="/Faq" element={<ProtectedRoute isAdmin={true} element={Faq} />} />
        <Route path="/LandingBanner" element={<ProtectedRoute isAdmin={true} element={LandingBanner} />} />
        <Route path="/UserBooking" element={<ProtectedRoute isAdmin={true} element={UserBooking} />} />
      </Routes>
    </>
  );
}


function UserRoutes(user) {
  const location = useLocation();
  const hideSidebar = location.pathname === "/login" || location.pathname === "/signup";
  return (
    <>
    {/* <Chatbot /> */}
      {!hideSidebar && <Navigation user={user.user} />}
      <Routes>
      <Route path="/Checkout" element={<PublicRoute isLoginUser={true} element={Checkout} />} />
      <Route path="/Success" element={<PublicRoute isLoginUser={true} element={SuccessFullPage} />} />
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignupForm />} />
        <Route path="/category/:id" element={<Services />} />
        <Route path="/profile" element={<Profile/>} />
        <Route path="/serviceDetail/:titleName" element={<ServiceDetails />} />
        <Route path="/FrequentlyQuestions" element={<FrequentlyQuestions />} />
        <Route path="/PrivacyAndPolicy" element={<PrivacyAndPolicy />} />
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        <Route path="/Address" element={<Address />} />
        <Route path="/BookingHistory" element={<BookingHistory />} />
        <Route path="/EditProfile" element={<EditProfile />} />
        <Route path="/ProfilePage" element={<ProfilePage />} />
        <Route path="/NeedHelp" element={<NeedHelp />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        {/* <Route path="/Checkout" element={<Checkout />} /> */}
       
      </Routes>
     
    </>
  );
}

export default App;
