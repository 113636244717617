import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import backgroundImage from "../../../../assets/1920.jpg";
import Footer from '../../inc/Footer/footer';
import MobileBottomNavigation from '../../mobile/mobilebottomnav/bottomnav';

const TermsAndConditions = () => {


  return (

    <React.Fragment>
      <Box style={style.heroSection} className="doorplusheroimg">
        <div style={style.overlay}></div>
        <div style={style.heroText}>
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            className="doorplusheroheading"
          >
           Terms And Conditions
          </Typography>
          
        </div>

      </Box>
      <Container  style={containerStyle}>
        <Paper elevation={0} style={paperStyle} className='doorplus-termmb'>
          <Typography variant="h4" gutterBottom style={headingStyle}>
            Terms and Conditions
          </Typography>
          <Typography paragraph style={paragraphStyle}>
            Welcome to DoorPlus These terms and conditions outline
            the rules and regulations for the use of our service.
          </Typography>
          <Typography paragraph style={paragraphStyle}>
            By accessing this service, we assume you accept these terms and conditions. Do not
            continue to use DoorPlus if you do not agree to take all of
            the terms and conditions stated on this page.
          </Typography>
          <div style={dividerStyle} />
          <Typography variant="h6" gutterBottom style={subheadingStyle}>
            Service Agreements
          </Typography>
          <Typography paragraph style={paragraphStyle}>
            These terms and conditions govern your use of our home appliance service app and the
            agreements between you and DoorPlus.
          </Typography>
          <div style={dividerStyle} />
          <Typography variant="h6" gutterBottom style={subheadingStyle}>
            Warranties
          </Typography>
          <Typography paragraph style={paragraphStyle}>
            We provide warranties for services rendered, ensuring quality and reliability in our
            repairs and maintenance work.
          </Typography>
          <div style={dividerStyle} />
          <Typography variant="h6" gutterBottom style={subheadingStyle}>
            Liabilities
          </Typography>
          <Typography paragraph style={paragraphStyle}>
            We shall not be liable for any damages arising from the use or inability to use our
            service, including but not limited to direct, indirect, incidental, or consequential
            damages.
          </Typography>
          <div style={dividerStyle} />
          <Typography variant="h6" gutterBottom style={subheadingStyle}>
            Payments
          </Typography>
          <Typography paragraph style={paragraphStyle}>
            Payment for services rendered must be made in accordance with the terms specified in our
            payment policies. Failure to pay may result in the suspension or termination of
            services.
          </Typography>
          <div style={dividerStyle} />
          <Typography variant="h6" gutterBottom style={subheadingStyle}>
            User Responsibilities
          </Typography>
          <Typography paragraph style={paragraphStyle}>
            Users are responsible for providing accurate information, maintaining the confidentiality
            of their account credentials, and complying with all applicable laws and regulations
            while using our service.
          </Typography>
        </Paper>
      </Container>
      <MobileBottomNavigation/>
    </React.Fragment>

  );
};

export default TermsAndConditions;


const style ={
  heroSection:{
    position: "relative",
    background: `linear-gradient(360deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 65.1%), url(${backgroundImage})`,
    WebkitBackgroundSize: "100%",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "450px",
    color: "white",
    textAlign: "center",
  },
  overlay: {
    position: "absolute",
    content: "",
    top: 0,
    left: 0,
    width: "100%",
    height: "auto",
    backgroundImage: "linear-gradient(to left, #000000, rgba(0, 0, 0, 0.5))",
  },
  heroText: {
    padding: "25px",
    zIndex: 1,
    marginTop:"30px",
    // marginBottom:'20px'
  },
}
const containerStyle = {
  marginBottom:'5rem'
  // position: 'relative',
  // zIndex: 2,
  // marginTop: '-15%',
};

const paperStyle = {
  borderRadius:'10px',
  padding: '30px',
  position: 'relative',
  zIndex: 2,
  marginTop: '-10%',
  backgroundColor: '#f9f9f9',
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
  marginBottom: '20px',
};

const headingStyle = {
  color: '#333',
  fontSize: '20px',
  marginBottom: '10px',
};

const paragraphStyle = {
  color: '#555',
  fontSize: '11px',
  marginBottom: '10px',
};

const subheadingStyle = {
  color: '#333',
  fontSize: '20px',
  marginBottom: '10px',
};

const dividerStyle = {
  marginTop: '20px',
  marginBottom: '20px',
  backgroundColor: '#ddd',
  height: '1px',
};