import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
} from '@mui/material';

import Sidebar from '../sidebar/sidebar';
import TopNav from '../sidebar/navbar';

const HomePage = () => {
  const cards = [
    { title: 'Total Users', count: 1234, color: '#42a5f5' },
    { title: 'Active Bookings', count: 250, color: '#66bb6a' },
    { title: 'Services Offered', count: 45, color: '#ffa726' },
    { title: 'Feedback Received', count: 320, color: '#ef5350' },
  ];

  return (
    <Box>
      <TopNav />
      <Box height={90} />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <Typography variant="h4" gutterBottom>
              Welcome Back, Admin
            </Typography>
          </Box>
          <Grid container spacing={3}>
            {cards.map((card, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card
                  sx={{
                    backgroundColor: card.color,
                    color: '#fff',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    borderRadius: 2,
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {card.title}
                    </Typography>
                    <Typography variant="h4" fontWeight="bold">
                      {card.count}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;
