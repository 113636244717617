import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import {useNavigate } from "react-router-dom";
import MuiAppBar from '@mui/material/AppBar';
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useAppStore } from '../appstore';
import { logoutUser } from '../../action/UserAuthActions';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: 'linear-gradient(135deg, rgba(255,255,255,0.98) 0%, rgba(240,242,255,0.98) 100%)',
  backdropFilter: 'blur(15px)',
  boxShadow: '0 4px 20px rgba(66, 42, 251, 0.06)',
  borderBottom: '1px solid rgba(66, 42, 251, 0.08)',
  height: '60px',
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '12px',
  backgroundColor: alpha('#fff', 0.95),
  marginRight: theme.spacing(1),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(2),
    width: 'auto',
  },
  boxShadow: '0 2px 8px rgba(66, 42, 251, 0.06)',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 4px 12px rgba(66, 42, 251, 0.1)',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#422afb',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#422afb',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1.2, 1, 1.2, 0),
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '35ch',
    },
    fontSize: '0.875rem',
  },
}));

const ActionIcon = styled(IconButton)(({ theme }) => ({
  color: '#422afb',
  backgroundColor: alpha('#fff', 0.95),
  borderRadius: '10px',
  padding: '8px',
  marginLeft: theme.spacing(1),
  boxShadow: '0 2px 8px rgba(66, 42, 251, 0.08)',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: alpha('#422afb', 0.08),
    boxShadow: '0 4px 12px rgba(66, 42, 251, 0.12)',
  },
}));

export default function TopNav() {
  const updateDopen = useAppStore((state) => state.updateDopen);
  const dopen = useAppStore((state) => state.dopen);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout =()=>{
    dispatch(logoutUser())
    navigate("/");
  }

  const handleFullScreen = () => {
    if (document.fullscreenEnabled) {
      document.documentElement.requestFullscreen();
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar sx={{ minHeight: '60px !important', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ActionIcon
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => { updateDopen(!dopen) }}
              sx={{ mr: 1 }}
            >
              <MenuIcon fontSize="small" />
            </ActionIcon>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                display: { xs: 'none', sm: 'block' },
                fontWeight: 700,
                color: '#422afb',
                ml: 1,
                letterSpacing: '0.5px',
                textShadow: '1px 1px 2px rgba(66, 42, 251, 0.08)',
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              DoorPlus
            </Typography>
          </Box>

          <Search sx={{ display: { xs: 'none', md: 'block' } }}>
            <SearchIconWrapper>
              <SearchIcon fontSize="small" />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search..."
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Full Screen" arrow>
              <ActionIcon onClick={handleFullScreen} sx={{ display: { xs: 'none', md: 'inline-flex' } }}>
                <FullscreenIcon fontSize="small" />
              </ActionIcon>
            </Tooltip>
            <Tooltip title="Notifications" arrow>
              <ActionIcon>
                <Badge badgeContent={1} color="error" sx={{ '& .MuiBadge-badge': { fontSize: '0.6rem', height: '14px', minWidth: '14px' } }}>
                  <NotificationsIcon fontSize="small" />
                </Badge>
              </ActionIcon>
            </Tooltip>
            <Box
              onClick={handleClick}
              sx={{
                ml: 1.5,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: alpha('#422afb', 0.06),
                borderRadius: '10px',
                padding: '3px 10px',
                transition: 'all 0.3s ease',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: alpha('#422afb', 0.1),
                },
              }}
            >
              <Avatar
                sx={{
                  width: 28,
                  height: 28,
                  bgcolor: '#422afb',
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                }}
              >
                M
              </Avatar>
              <Typography
                sx={{
                  ml: 1,
                  fontWeight: 600,
                  fontSize: '0.75rem',
                  color: '#422afb',
                }}
              >
                Admin
              </Typography>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <AccountCircle fontSize="small" />
          </ListItemIcon>
          Profile
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
}