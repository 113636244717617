import axios from "axios";
import {
  LOAD_USER_FAILURE,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  CLEAR_ERRORS,
  USERD_REQUEST,
  USERD_SUCCESS,
  USERD_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE
} from "../constant/UserAuthConstant";
import siteConfig from "../siteConfig";

export const loginUser = (Data) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${siteConfig.base_URL}/api/v1/loginuser`,
      Data,
      config
    );

   localStorage.setItem('token', data.token);
   localStorage.setItem('user', JSON.stringify(data.user));

    dispatch({ type: LOGIN_SUCCESS, payload: data.user });
  } catch (error) {
    dispatch({ type: LOGIN_FAILURE, payload: error.message });
  }
};

export const registerUser = (registerData) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { signupData } = await axios.post(
      `${siteConfig.base_URL}/api/v1/register`,
      registerData,
      config
    );
    dispatch({ type: REGISTER_SUCCESS, payload: signupData });
  } catch (error) {
    dispatch({ type: REGISTER_FAILURE, payload: error?.response?.data });
  }
};
// clear error
export const clearError = ()=> async(dispatch)=>{
  dispatch({
      type:CLEAR_ERRORS
  })
   }
   export const loadUser = () => async (dispatch) => {
    try {
      dispatch({ type: LOAD_USER_REQUEST });
  
      const token = localStorage.getItem("token");
  
      if (!token) {
        dispatch({ type: LOAD_USER_FAILURE, payload:null });
        return;
      }
  
      try {
        // Check if user data is in local storage
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
          dispatch({
            type: LOAD_USER_SUCCESS,
            payload: JSON.parse(storedUser),
          });
          return;
        }
      } catch (error) {
        console.error("Error parsing stored user:", error);
      }
  
      // Fetch user from API if not in localStorage
      const { data } = await axios.get(`${siteConfig.base_URL}/api/v1/me`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      localStorage.setItem("user", JSON.stringify(data.user));
      dispatch({
        type: LOAD_USER_SUCCESS,
        payload: data.user,
      });
    } catch (error) {
      dispatch({
        type: LOAD_USER_FAILURE,
        payload: error.response?.data?.message || "Failed to load user",
      });
    }
  };
  
  

export const logoutUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOGOUT_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios.get(`${siteConfig.base_URL}/api/v1/logoutuser`, config);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch({ type: LOGOUT_SUCCESS });
  } catch (error) {
    dispatch({ type: LOGOUT_FAILURE, payload: error.message });
  }
};
export const user_Detail = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch({ type: USERD_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
        "token":token
      },
    };

    await axios.get(`${siteConfig.base_URL}/api/v1/me`, config);
    dispatch({ type: USERD_SUCCESS });
  } catch (error) {
    dispatch({ type: USERD_FAILURE, payload: error.message });
  }
};

export const updateUser = (userData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_REQUEST });

    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        "token": token,
      },
    };

    const { data } = await axios.put(
      `${siteConfig.base_URL}/api/v1/editProfile`, 
      userData,
      config
    );
    localStorage.setItem("user", JSON.stringify(data.user));

    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAILURE,
      payload: error?.response?.data || error.message,
    });
  }
};