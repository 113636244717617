import React from "react";
import Banner from "../user/pages/banner/banner.jsx";
import Allcategory from "../user/pages/allCategory/Allcategory.jsx";
import Footer from "../user/inc/Footer/footer.jsx";
import MobileBottomNavigation from "../user/mobile/mobilebottomnav/bottomnav.jsx";
import  Plumbing from "../user/pages/Plumbing/Plumbing.jsx" 

const Home = () => {
  return (
    <>
      <Banner />
      <Allcategory />
      {/* <Plumbing/> */}
      <MobileBottomNavigation />
      <Footer />
    </>
  );
};

export default Home;
