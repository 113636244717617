export const SERVICE_REQUEST = 'SERVICE_REQUEST';
export const SERVICE_SUCCESS = 'SERVICE_SUCCESS';
export const SERVICE_FAIL = 'SERVICE_FAIL';
export const SERVICE_RESET = 'SERVICE_RESET';

export const ALL_SERVICE_REQUEST = 'ALL_SERVICE_REQUEST';
export const ALL_SERVICE_SUCCESS = 'ALL_SERVICE_SUCCESS';
export const ALL_SERVICE_FAIL = 'ALL_SERVICE_FAIL';

export const DELETE_SERVICE_REQUEST = 'DELETE_SERVICE_REQUEST';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAIL = 'DELETE_SERVICE_FAIL';
export const DELETE_SERVICE_RESET = 'DELETE_SERVICE_RESET';

export const UPDATE_SERVICE_REQUEST = 'UPDATE_SERVICE_REQUEST';
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';
export const UPDATE_SERVICE_FAIL = 'UPDATE_SERVICE_FAIL';
export const UPDATE_SERVICE_RESET = 'UPDATE_SERVICE_RESET';


