
import { ALL_USER_BOOKING_FAIL, ALL_USER_BOOKING_REQUEST, ALL_USER_BOOKING_SUCCESS, DELETE_USER_BOOKING_FAIL, DELETE_USER_BOOKING_REQUEST, DELETE_USER_BOOKING_SUCCESS, UPDATE_USER_BOOKING_FAIL, UPDATE_USER_BOOKING_REQUEST, UPDATE_USER_BOOKING_SUCCESS } from "../constant/UserServiceBookingConstant";
  import siteConfig from "../siteConfig";
  import axios from "axios";
export const getAllBooking = () => async (dispatch) => {
    try {
      dispatch({ type: ALL_USER_BOOKING_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      };
      const { data } = await axios.get(
        `${siteConfig.base_URL}/api/v1/admin/AllBooking`,
        config
      );
      dispatch({ type: ALL_USER_BOOKING_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: ALL_USER_BOOKING_FAIL, payload: error.message });
    }
  };
  
  export const deleteUserBooking = (_id) => async (dispatch) => {
    try {
      dispatch({
        type: DELETE_USER_BOOKING_REQUEST
      });
      const config = {
        headers: {
          "content-type": "application/json",
          token: localStorage.getItem("token"),
        }
      };
      const {data} = await axios.delete(
        `${siteConfig.base_URL}/api/v1/admin/bookings/${_id}`,
        config
      );
      dispatch({type:DELETE_USER_BOOKING_SUCCESS,payload:data})
    } catch (error) {
     dispatch({
      type:DELETE_USER_BOOKING_FAIL ,payload:error.message
     })
    }
  }
  
  export const updateUserBooking = (bookingId, service) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_USER_BOOKING_REQUEST });
  
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        }
      };
  
      // Ensure the status is "confirmed"
      const updatedService = { status: service};
  
      const { data } = await axios.put(
        `${siteConfig.base_URL}/api/v1/admin/bookings/${bookingId}`, 
        updatedService,
        config
      );
  
      dispatch({ type: UPDATE_USER_BOOKING_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ 
        type: UPDATE_USER_BOOKING_FAIL, 
        payload: error.response?.data?.message || error.message 
      });
    }
  };