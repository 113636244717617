// import { ADD_TO_CART, REMOVE_FROM_CART } from '../constant/cartConstant';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// // Action to add service to cart
// export const addToCart = (service) => (dispatch, getState) => {
//   console.log(service, "service");

//   const { cart } = getState().cart; // Access the cart state

//   // Check if the service already exists in the cart
//   const exists = cart.some((item) => item._id === service._id);

//   if (exists) {
//     // If the service is already in the cart, show an error toast
//     toast.error(`${service.name} is already in your cart!`, {
//       style: { background: 'white', color: 'red', fontSize: '0.9rem' },
//     });
//     return; // Don't proceed if the service is already in the cart
//   }

//   // Add the service to the cart
//   const updatedCart = [...cart, service];

//   // Dispatch action to update Redux state with the new cart
//   dispatch({
//     type: ADD_TO_CART,
//     payload: updatedCart,
//   });

//   // Sync the updated cart with localStorage
//   localStorage.setItem('cart', JSON.stringify(updatedCart));

//   // Show a success toast indicating the service has been added to the cart
//   toast.success(`Service has been added to your cart!`, {
//     style: { background: 'white', color: 'green', fontSize: '0.9rem' },
//   });
// };
// export const removeFromCart = (serviceId) => (dispatch, getState) => {
//   const { cart } = getState().cart;

//   // Filter out the service by its _id
//   const updatedCart = cart.filter((item) => item._id !== serviceId);

//   // Dispatch action to update Redux state
//   dispatch({
//     type: REMOVE_FROM_CART,
//     payload: updatedCart,
//   });

//   // Sync the updated cart with localStorage
//   localStorage.setItem('cart', JSON.stringify(updatedCart));

//   // Show a success toast indicating the service has been removed from the cart
//   toast.success('Service has been removed from your cart!', {
//     style: { background: 'white', color: 'green', fontSize: '0.9rem' },
//   });
// };
import axios from 'axios';
import { ADD_TO_CART, ALL_CART_FAIL, ALL_CART_REQUEST, ALL_CART_SUCCESS, REMOVE_FROM_CART } from '../constant/cartConstant';
import { toast } from 'react-toastify';
import siteConfig from "../siteConfig";
import 'react-toastify/dist/ReactToastify.css';

// **Add Service to Cart**
export const addToCart = (service, userId) => async (dispatch, getState) => {
  try {
    const { cart } = getState().cart || { cart: { services: [] } }; // Ensure cart exists

    // Ensure services array exists
    const services = cart?.services || [];
debugger
    // Check if service is already in the cart
    const exists = services.some((item) => item.serviceId._id === service._id);
    if (exists) {
      toast.error(`${service.name} is already in your cart!`, {
        style: { background: 'white', color: 'red', fontSize: '0.9rem' },
      });
      return;
    }

    // API request to add service to cart
    const { data } = await axios.post(`${siteConfig.base_URL}/api/v1/add`, {
      userId,
      serviceId: service._id,
      quantity: 1, // Default quantity
    });

    // Dispatch action to update Redux state
    dispatch({
      type: ADD_TO_CART,
      payload: data.cart, // Update cart with response from API
    });

    toast.success(`Service added to cart!`, {
      style: { background: 'white', color: 'green', fontSize: '0.9rem' },
    });

  } catch (error) {
    console.error('Error adding to cart:', error);
    toast.error('Failed to add service to cart!');
  }
};


// **Remove Service from Cart**
export const removeFromCart = (serviceId, userId) => async (dispatch, getState) => {
  try {
    // API request to remove service from cart
    await axios.post(`${siteConfig.base_URL}/api/v1/remove`, { userId, serviceId });

    const { cart } = getState().cart;
    const updatedCart = cart.services.filter((item) => item.serviceId !== serviceId);

    // Dispatch action to update Redux state
    dispatch({
      type: REMOVE_FROM_CART,
      payload: updatedCart,
    });

    // Sync with localStorage
    // localStorage.setItem('cart', JSON.stringify(updatedCart));

    toast.success('Service removed from your cart!', {
      style: { background: 'white', color: 'green', fontSize: '0.9rem' },
    });

  } catch (error) {
    console.error('Error removing from cart:', error);
    toast.error('Failed to remove service from cart!');
  }
};

// **Get User Cart**
export const getAllCart = (userId) => async (dispatch) => {
  try {
    dispatch({ type: ALL_CART_REQUEST });

    const { data } = await axios.get(`${siteConfig.base_URL}/api/v1/cart/${userId}`);

    dispatch({ type: ALL_CART_SUCCESS, payload: data.cart });

  } catch (error) {
    dispatch({ type: ALL_CART_FAIL, payload: error.message });
    toast.error('Failed to load cart data!');
  }
};
