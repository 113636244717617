import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, Typography, Button, Badge, Avatar, Divider, CardContent, Container, Skeleton, CircularProgress, colors } from '@mui/material';
import { styled } from '@mui/system';
import { ShoppingCart, LocalOffer, Add, Close } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { getAllCart, removeFromCart } from '../../../action/cartAction';
const PremiumButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(135deg, #000DFF 0%, #6B73FF 100%)',
    color: '#FFFFFF',
    fontWeight: 600,
    borderRadius: 6,
    padding: '6px 16px',
    border: '3px solid white',
    boxShadow: '0 8px 25px rgba(0, 13, 255, 0.1)',
    fontSize: '0.8rem',
    textTransform: 'none',
    '&:hover': {
      background: 'linear-gradient(135deg, #0009CC 0%, #5158CC 100%)'
    },
    '&:disabled': {
      background: '#CCCCCC',
      color: '#666666',
    },
  }));
 
const Cart=()=>{
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { cart } = useSelector((state) => state.cart);
   const { user, loading, error } = useSelector((state) => state.user);
   console.log(cart,"0000000000000000")
  const handleRemoveService = (serviceId) => {
    dispatch(removeFromCart(serviceId, user?._id));
     setTimeout(() => {
            dispatch(getAllCart(user._id));
          }, 100);
  };
  useEffect(() => {
     if (user!=null) {
      dispatch(getAllCart(user?._id))
     }
   }, [loading,dispatch]); 
  const navigateCategory = () => {
      navigate(`/checkout`);
  }
  const calculateCartTotals = (cartItems) => {
    let totalBasePrice = 0;
    let totalDiscount = 0;
    cartItems?.forEach((item) => {
      const discountAmount = (item?.serviceId?.price?.basePrice * item?.serviceId?.price?.discount) / 100;
      totalBasePrice += item?.serviceId?.price?.basePrice;
      totalDiscount += discountAmount;
    });
  
    const totalPayable = totalBasePrice - totalDiscount;
  
    return { totalBasePrice, totalDiscount, totalPayable };
  };
  const { totalBasePrice, totalDiscount, totalPayable } = calculateCartTotals(cart.services);
    return(
        <StyledCard sx={{ mb: 3, overflow: 'hidden', position: 'relative' }}>
              <Box sx={{ background: 'linear-gradient(135deg, #000DFF 0%, #6B73FF 100%)', p: 2, textAlign: 'center', borderRadius: '12px 12px 50% 50% / 12px 12px 30px 30px' }}>
                <Badge badgeContent={cart?.services?.length} color="error" sx={{ '& .MuiBadge-badge': { top: 5, right: 5 } }}>
                  <ShoppingCart sx={{ fontSize: 36, color: '#FFFFFF' }} />
                </Badge>
                <Typography variant="h6" sx={{ mt: 1, fontWeight: 700, color: '#FFFFFF' }}>Your Cart</Typography>
              </Box>
              <CardContent sx={{ p: 2 }}>
                {console.log(cart?.services?.length,"gfdfgfggffgd")}
                {cart?.services?.length === 0 ||cart?.services?.length === undefined ? (
                  <Typography variant="body1" textAlign="center" sx={{ my: 2, color: 'text.secondary' }}>
                    Your cart is empty
                  </Typography>
                ) : (
                  <>
                    {cart?.services?.map((item) => (
                      <Box key={item._id} sx={{ display: 'flex', alignItems: 'center', mb: 2, p: 1, borderRadius: 2, background: 'rgba(0, 13, 255, 0.05)' }}>
                        <Box component="img" sx={{ width: 40, height: 40, borderRadius: 1, mr: 2 }} src={item?.serviceId?.image?.url} alt='img' />
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>{item?.serviceId?.name}</Typography>
                          <Typography variant="caption">₹{item?.serviceId?.price?.basePrice}</Typography>
                        </Box>
                       
                        <Button
                          size="small"
                          sx={{ minWidth: 'auto', p: 0.5 }}
                         onClick={() => handleRemoveService(item?.serviceId._id)}
                        >
                          <Close fontSize="small" />
                        </Button>
                      </Box>
                    ))}
                     <Divider sx={{ marginBottom: 2 }} />

<Grid container justifyContent="space-between" sx={{ marginBottom: 1 }}>
  <Typography variant="body1">Price (1 item)</Typography>
  <Typography variant="body1">₹{totalBasePrice}</Typography>
</Grid>

<Grid container justifyContent="space-between" sx={{ marginBottom: 1 }}>
  <Typography variant="body1">Discount</Typography>
  <Typography variant="body1" color="green">-₹{totalDiscount}</Typography>
</Grid>

<Divider sx={{ marginY: 2 }} />

<Grid container justifyContent="space-between" sx={{ marginBottom: 1 }}>
  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Total Amount</Typography>
  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>₹{totalPayable}</Typography>
</Grid>
            
                    <PremiumButton onClick={navigateCategory} fullWidth size="small" sx={{ mt: 1 }}>Checkout</PremiumButton>
                  </>
                )}
              </CardContent>
            </StyledCard>
    )
}
const StyledCard = styled(Card)(({ theme }) => ({
    background: 'rgba(255, 255, 255, 0.25)',
    borderRadius: '12px',
    backdropFilter: 'blur(5px)',
    boxShadow: '0 8px 25px rgba(0, 13, 255, 0.1)',
    border: '4px solid white',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-3px)',
      boxShadow: '0 8px 25px rgba(0, 13, 255, 0.1)'
    },
  }));
export default Cart;