
import React, { useState, useEffect } from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, IconButton, Box, Dialog, DialogContent, DialogActions, Button, TablePagination, DialogTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { Frequentlyans, deleteFaq } from '../../action/FaqActions';

const FaqPreviews = () => {
    const dispatch = useDispatch();
    const { getFaq } = useSelector((state) => state.getFaq);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(6);
    const [faqDelete, setFaqDelete] = useState(0);

    useEffect(() => {
        dispatch(Frequentlyans());
    }, [dispatch]);

    const handleDelete = (_id) => {
        setOpenDeleteDialog(true);
        setFaqDelete(_id);
    };

    const handleConfirmDelete = async () => {
        setOpenDeleteDialog(false);
        await dispatch(deleteFaq(faqDelete));
        dispatch(Frequentlyans());
    };

    const handleCancelDelete = () => {
        setOpenDeleteDialog(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <Container maxWidth="md" sx={{ mt: 2, mb: 4 }}>

            <Box sx={{
                borderRadius: '20px',
                overflow: 'hidden',
                boxShadow: '0 4px 20px rgba(66, 42, 251, 0.08)',
                border: '1px solid rgba(66, 42, 251, 0.12)',
            }}>
                <Box sx={{ borderBottom: '2px solid #422afb', padding: '10px 16px', background: " rgba(66, 42, 251, 0.08)", }}>
                    <Typography sx={{ fontSize: '0.875rem', fontWeight: 600, color: '#422afb' }}>
                        Frequently Asked Questions
                    </Typography>
                </Box>
                {getFaq?.data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((faq, index) => (
                    <Accordion
                        key={index}
                        elevation={0}
                        sx={{
                            '&:before': { display: 'none' },
                            '&:not(:last-child)': { borderBottom: '1px solid rgba(66, 42, 251, 0.08)' },
                            background: 'transparent',
                            '&:nth-of-type(odd)': {
                                backgroundColor: 'rgba(66, 42, 251, 0.05)',
                              },
                              '&:nth-of-type(even)': {
                                backgroundColor: '#ffffff',
                              },
                              '&:hover': {
                                backgroundColor: 'rgba(66, 42, 251, 0.1)',
                                boxShadow: '0 4px 20px 0 rgba(66, 42, 251, 0.1)',
                                transform: 'translateY(-2px)',
                                transition: 'all 0.3s',
                              },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<AddIcon sx={{ fontSize: 16, color: '#422afb' }} />}
                            sx={{
                                minHeight: '40px',
                                '& .MuiAccordionSummary-content': { margin: '6px 0' },
                                '&:hover': { backgroundColor: 'rgba(66, 42, 251, 0.03)' }
                            }}
                        >
                            <Typography sx={{ fontSize: '0.75rem', fontWeight: 500, color: '#333' }}>
                                {faq.Faq.Questions}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ pt: 0, pb: 1, px: 2 }}>
                            <Typography sx={{ fontSize: '0.7rem', mb: 1, color: '#555', lineHeight: 1.4 }}>
                                {faq.Faq.Answers}
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: "flex-end" }}>
                                <IconButton size="small" sx={{ color: '#422afb', p: 0.3 }}>
                                    <EditOutlinedIcon sx={{ fontSize: 16 }} />
                                </IconButton>
                                <IconButton size="small" sx={{ color: '#ff3d57', p: 0.3 }} onClick={() => handleDelete(faq._id)}>
                                    <DeleteOutlineIcon sx={{ fontSize: 16 }} />
                                </IconButton>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>

            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: "25px 0px",
            }}>
                <TablePagination
                    style={{
                        boxShadow: '0 4px 20px rgba(66, 42, 251, 0.08)',
                        border: '1px solid rgba(66, 42, 251, 0.12)',
                        background: " rgba(66, 42, 251, 0.08)",
                        borderRadius: "10px",
                        color: "#422afb",
                        maxWidth: "fit-content",
                    }}
                    rowsPerPageOptions={[6, 12, 18]}
                    component="div"
                    count={getFaq?.data?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
            <Dialog
                open={openDeleteDialog}
                onClose={handleCancelDelete}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle style={{ background: "rgb(198, 215, 253)", color: 'rgb(66, 42, 251)', fontSize: '12px' }}>Delete Confirmation</DialogTitle>
                <DialogContent style={{ marginTop: "20px", fontSize: '12px' }}>
                    Are you sure you want to delete this item?
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<ClearOutlinedIcon />} onClick={handleCancelDelete} style={{ backgroundColor: 'rgb(66, 42, 251)', color: 'white', fontSize: "12px", textTransform: "capitalize" }}>
                        Cancel
                    </Button>
                    <Button startIcon={<DeleteIcon />} onClick={handleConfirmDelete} style={{ backgroundColor: 'red', color: 'white', fontSize: "12px", textTransform: "capitalize" }}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default FaqPreviews;
