import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, Typography, Button, Badge, Avatar, Rating, CardContent, Container, Skeleton, CircularProgress, colors } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation, useNavigate,useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchSubCetegory } from '../../../action/subCategory';
import { getAllService } from '../../../action/serviceAction';
import { getCategory } from '../../../action/catgoryAction';
 
const OtherCat=()=>{
   const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  console.log(id,"kkkkk")
  const { getAllCategory, loading: categoryLoading } = useSelector((state) => state.getAllCategory);
  const [categoryData, setCategoryData] = useState(getAllCategory);
  useEffect(() => {
    // Dispatch an action to fetch data for the new category ID
    if (id) {
     dispatch(getCategory())
     dispatch(fetchSubCetegory(id));
    }
  }, [id, dispatch]);
  useEffect(() => {
    if (getAllCategory) setCategoryData(getAllCategory);
  }, [getAllCategory]);
  const navigateCategory = (obj) => navigate(`/category/${obj._id}`, { state: { selectedCategoryId: obj._id } });
    return(
        <StyledCard>
              <Box sx={{ position: 'relative', mb: 1, mt: 2, textAlign: 'center' }}>
                <Typography variant="h5" sx={{ fontWeight: 500, color: '#000DFF', display: 'inline-block', position: 'relative' }}>
                  Other Services
                </Typography>
                <Box sx={{ position: 'absolute', bottom: '-10px', left: '50%', transform: 'translateX(-50%)', width: '60%', height: '1px', background: 'linear-gradient(90deg, transparent, #000DFF, transparent)' }} />
              </Box>
              <Box sx={{marginTop:"30px"}}>
              {categoryData?.categories?.map((item, index) => (
                  <Box onClick={() => navigateCategory(item)} key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1, p: 1, borderRadius: 2, background: 'rgba(0, 13, 255, 0.05)' }}>
                    <Avatar sx={{ bgcolor: '#FFFFFF', color: '#000DFF', width: 44, height: 44, fontSize: '1.2rem', mr: 2, boxShadow: '0 4px 10px rgba(0, 13, 255, 0.2)', borderRadius:"10px" }}> <img src={item?.image?.url} style={{width:"70px"}} /></Avatar>
                    <Typography variant="body2" sx={{ color: '#666666', fontWeight: 600 }}>{item?.title}</Typography>
                  </Box>
                ))}
              </Box>
            </StyledCard>
    )
}
const StyledCard = styled(Card)(({ theme }) => ({
    background: 'rgba(255, 255, 255, 0.25)',
    borderRadius: '12px',
    backdropFilter: 'blur(5px)',
    boxShadow: '0 8px 25px rgba(0, 13, 255, 0.1)',
    border: '4px solid white',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-3px)',
      boxShadow: '0 8px 25px rgba(0, 13, 255, 0.1)'
    },
  }));
export default OtherCat;