
import React, { useState } from "react";
import {
    Box,
    Button,
    Card,
    InputLabel,
    TextField,
    Typography,
} from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { FaqCreate } from "../../action/FaqActions";

const FaqCreateForm = () => {
    const [faq, setFaq] = useState({
        Questions: "",
        Answers: "",
    });

    const dispatch = useDispatch();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFaq((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const validateInput = () => {
        const { Questions, Answers } = faq;
        if (!Questions.trim() || !Answers.trim()) {
            toast.error("Please fill all required fields");
            return false;
        }
        if (Questions.length < 10 || Questions.length > 200) {
            toast.error("Questions must be between 10 and 200 characters");
            return false;
        }
        if (Answers.length < 20 || Answers.length > 1000) {
            toast.error("Answers must be between 20 and 1000 characters");
            return false;
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateInput()) {
            dispatch(FaqCreate(faq));
            setFaq({
                Questions: "",
                Answers: "",
            });
            toast.success("FAQ created successfully!");
        }
    };
    return (
        <Box sx={{ maxWidth: "500px", margin: "20px auto" }}>
            <Card
                elevation={0}
                sx={{
                    padding: "28px",
                    borderRadius: '12px',
                    background: '#ffffff',
                    boxShadow: '0 4px 20px rgba(66, 42, 251, 0.08)',
                    border: '1px solid rgba(66, 42, 251, 0.12)',
                }}
            >
                <form onSubmit={handleSubmit}>
                    <Box mb={2}>
                        <InputLabel
                            htmlFor="faq-questions"
                            sx={{ mb: 1, fontSize: "13px", color: "#422afb", fontWeight: 600, letterSpacing: '0.5px' }}
                        >
                            Question
                        </InputLabel>
                        <TextField 
                            id="faq-questions"
                            name="Questions"
                            value={faq.Questions} 
                            onChange={handleChange} 
                            fullWidth 
                            variant="outlined" 
                            size="small"
                            placeholder="Enter your question here"
                            InputProps={{ sx: inputStyle }} 
                        />
                    </Box>
                    <Box mb={3}>
                        <InputLabel
                            htmlFor="faq-answers"
                            sx={{ mb: 1, fontSize: "13px", color: "#422afb", fontWeight: 600, letterSpacing: '0.5px' }}
                        >
                            Answer
                        </InputLabel>
                        <TextField
                            multiline
                            rows={4}
                            id="faq-answers"
                            name="Answers"
                            value={faq.Answers}
                            onChange={handleChange}
                            fullWidth 
                            variant="outlined" 
                            size="small"
                            placeholder="Enter your answer here"
                            InputProps={{ sx: inputStyle }} 
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button 
                                type="submit" 
                                variant="contained" 
                                sx={{ 
                                    mt: 1,
                                    backgroundColor: "#422afb",
                                    color: 'white',
                                    padding: "10px 24px",
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    borderRadius: '12px',
                                    textTransform: 'none',
                                    boxShadow: 'none',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: "#3621d4",
                                        boxShadow: '0 4px 12px rgba(66, 42, 251, 0.3)',
                                    }
                                }}
                            >
                                Create FAQ
                            </Button>
                    </Box>
                </form>
            </Card>
            <ToastContainer position="top-right" autoClose={3000} />
        </Box>
    );
};

export default FaqCreateForm;

const inputStyle = {
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(66, 42, 251, 0.2)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(66, 42, 251, 0.5)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#422afb',
    },
    borderRadius: '12px',
    fontSize: '13px',
    backgroundColor: '#f9fafc',
    transition: 'all 0.3s ease',
    '&:hover': {
        backgroundColor: '#f0f2f8',
    },
};