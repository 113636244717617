import React, { useEffect, useState } from "react";
import { Table, InputLabel, MenuItem, Select, Stack, Grid, TableHead, TableBody, TableRow, TableCell, Paper, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, TextField } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
//import SearchBar from "../../sidebar/searchbar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCategory } from "../../../action/catgoryAction";
import { useDispatch, useSelector } from "react-redux";
import { deleteSubCategory, fetchSubCetegory } from "../../../action/subCategory";



const SubcategoryList = ({ handleEdit }) => {
    const { getAllCategory } = useSelector((state) => state.getAllCategory);
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const { fetchAllSubCategory } = useSelector((state) => state.fetchAllSubCategory);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [subCategoryDelete, setsubCategoryDelete] = useState(0);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCategory());
    }, [dispatch]);

    const handleCategoryChange = (event) => {
        setSelectedCategoryId(event.target.value);
        dispatch(fetchSubCetegory(event.target.value));
    };

    const handleDelete = (_id) => {
        setOpenDeleteDialog(true);
        setsubCategoryDelete(_id)
    };

    const handleConfirmDelete = async () => {
        setOpenDeleteDialog(false);
        await dispatch(deleteSubCategory(subCategoryDelete));
         dispatch(fetchSubCetegory());
         toast.error("Category deleted successfully")
    };
    const handleCancelDelete = () => {
        setOpenDeleteDialog(false);
    };

    const formatDate = (date) => {
        const formattedDate = new Date(date).toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour12: true
        });
        return formattedDate;
    };

    return (
        <>
            <Box height={15} />
            <Grid container spacing={2}>
                <Grid item md={12} lg={4} sm={12}>
                    <Stack spacing={1}>
                        <InputLabel
                            htmlFor="subcategory"
                            style={{ marginBottom: "5px", fontSize: "12px", color: "#4b4b4b" }}
                        >
                            select Category<span style={{ color: "#f44336" }}>*</span>
                        </InputLabel>
                        <Select
                            style={{
                                background: "white",
                                boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"
                            }}
                            value={selectedCategoryId}
                            onChange={handleCategoryChange}
                            fullWidth
                            variant="outlined"
                            displayEmpty
                            inputProps={{
                                style: { borderColor: "#c4c4c4" },
                            }}
                        >
                            <MenuItem value="">
                                Select Subcategory
                            </MenuItem>
                            {
                                getAllCategory?.categories?.map((item) => (
                                    <MenuItem value={item._id}
                                        key={item._id}>{item.title}</MenuItem>
                                ))
                            }
                        </Select>
                    </Stack>
                </Grid>
            </Grid>
            <Paper style={{
                margin: "20px 0px",
                boxShadow: "rgba(17, 17, 26, 0.1) 0px 0px 16px"

            }}>
                <Table>

                    <TableHead sx={{ backgroundColor: 'rgba(66, 42, 251, 0.1)' }}>
                        <TableRow >
                            <TableCell sx={{ color: '#422afb', fontWeight: 'bold', fontSize: "11px", padding: '15px', borderBottom: '2px solid #422afb' }}>Serial Number</TableCell>
                            <TableCell sx={{ color: '#422afb', fontWeight: 'bold', fontSize: "11px", padding: '15px', borderBottom: '2px solid #422afb' }}>SubCategory Name</TableCell>
                            <TableCell sx={{ color: '#422afb', fontWeight: 'bold', fontSize: "11px", padding: '15px', borderBottom: '2px solid #422afb' }}>Description</TableCell>
                            <TableCell sx={{ color: '#422afb', fontWeight: 'bold', fontSize: "11px", padding: '15px', borderBottom: '2px solid #422afb' }}>Image</TableCell>
                            <TableCell sx={{ color: '#422afb', fontWeight: 'bold', fontSize: "11px", padding: '15px', borderBottom: '2px solid #422afb' }}>created Date</TableCell>
                            <TableCell sx={{ color: '#422afb', fontWeight: 'bold', fontSize: "11px", padding: '15px', borderBottom: '2px solid #422afb' }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedCategoryId?<>
                            {fetchAllSubCategory?.getsubcategory?.map((subcategory, index) => (
                            <TableRow key={subcategory._id} style={{ backgroundColor: '#fafafa', borderBottom: '1px solid #dbdbdb' }}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell style={{ textTransform: "capitalize", }}>{subcategory.SubcategoryTitle}</TableCell>
                                <TableCell style={{ textTransform: "capitalize", }}>{subcategory.description}</TableCell>
                                <TableCell><img src={subcategory.image?.url} style={{ width: '50px', height: '50px' }} /></TableCell>
                                <TableCell style={{ textTransform: "capitalize", }}>{formatDate(subcategory.createdAt)}</TableCell>
                                <TableCell>
                                    <IconButton aria-label="edit" style={{ color: 'green' }} onClick={() => handleEdit(subcategory)}>
                                        <EditIcon style={{ fontSize: "19px" }} />
                                    </IconButton>
                                    <IconButton aria-label="delete" onClick={() => handleDelete(subcategory._id)} style={{ color: 'red' }}>
                                        <DeleteIcon style={{ fontSize: "19px" }} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        </>:null}
                        
                    </TableBody>
                </Table>
                </Paper>
            <Dialog
                style={{ marginLeft: "36px" }}
                open={openDeleteDialog}
                onClose={handleCancelDelete}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle style={{ background: "#4b4b4b", color: 'white' }}>Delete Confirmation</DialogTitle>
                <DialogContent style={{ marginTop: "20px" }}>
                    Are you sure you want to delete this item?
                </DialogContent>
                <DialogActions style={{ padding: "20px" }}>
                    <Button startIcon={<ClearOutlinedIcon />} onClick={handleCancelDelete} style={{ backgroundColor: '#4b4b4b', color: 'white', fontSize: "12px", textTransform: "capitalize" }}>
                        Cancel
                    </Button>
                    <Button startIcon={<DeleteIcon />} onClick={handleConfirmDelete} style={{ backgroundColor: 'red', color: 'white', fontSize: "12px", textTransform: "capitalize" }}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    );
}

export default SubcategoryList;
