import React, { useRef, useState } from "react";
import { Button, Card, Grid, InputLabel, TextField, Typography, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { blogCreate } from "../../action/blogAction";
import JoditEditor from "jodit-react";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const CreateBlog = () => {
    const [Blog, setBlog] = useState({
        Blogtitle: "",
        description: "",
        file: null
    });
    const dispatch = useDispatch();
    const editor = useRef(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBlog((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleEditorChange = (content) => {
        setBlog((prevState) => ({
            ...prevState,
            description: content,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!Blog.Blogtitle || !Blog.description) {
            toast.error("Please fill all required fields");
            return;
        }
        dispatch(blogCreate(Blog));
        setBlog({
            Blogtitle: "",
            description: "",
            file: null,
        });
    };

    const fileInputRef = useRef(null);

    const handleUploadButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setBlog((prevState) => ({
                ...prevState,
                file: file,
            }));
        }
    };

    const handleRemoveImage = () => {
        setBlog((prevState) => ({
            ...prevState,
            file: null,
        }));
    };


    return (
        <Box sx={{ maxWidth: "100%", margin: "10px auto" }}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Card
                            elevation={0}
                            sx={{
                                padding: { xs: "15px", sm: "20px" },
                                borderRadius: '12px',
                                background: '#ffffff',
                                boxShadow: '0 4px 20px rgba(66, 42, 251, 0.08)',
                                border: '1px solid rgba(66, 42, 251, 0.12)',
                            }}
                        >
                            <Box mb={2}>
                                <InputLabel
                                    htmlFor="Blogtitle"
                                    sx={{ mb: 1, fontSize: "13px", color: "#422afb", fontWeight: 600, letterSpacing: '0.5px' }}
                                >
                                    Blog title
                                </InputLabel>
                                <TextField
                                    id="Blogtitle"
                                    name="Blogtitle"
                                    value={Blog.Blogtitle}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    placeholder="Enter your Blog title here"
                                    InputProps={{ sx: inputStyle }}
                                />
                            </Box>
                            <Box mb={2}>
                                <InputLabel
                                    htmlFor="description"
                                    sx={{ mb: 1, fontSize: "13px", color: "#422afb", fontWeight: 600, letterSpacing: '0.5px' }}
                                >
                                    Blog Description
                                </InputLabel>
                                <JoditEditor
                                    ref={editor}
                                    value={Blog.description}
                                    tabIndex={1}
                                    onBlur={handleEditorChange}
                                    placeholder="Enter your Blog description here"
                                />
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        < Box sx={webStyle.UploadContainer} elevation={0}>
                            {Blog.file ? (
                                <>
                                    <Box sx={webStyle.ImagePreview}>
                                        <img src={URL.createObjectURL(Blog.file)} alt="Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                    </Box>
                                    <Box display="flex" justifyContent="center">
                                        < Button sx={webStyle.StyledButton} onClick={handleRemoveImage} variant="outlined" color="secondary" startIcon={<DeleteOutlineIcon />} size="small">
                                            Remove
                                        </Button>
                                    </Box>
                                </>
                            ) : (
                                <Box sx={webStyle.UploadArea}
                                    onClick={handleUploadButtonClick}
                                    whileHover={{ scale: 1.02, boxShadow: '0 4px 15px rgba(66, 42, 251, 0.15)' }}
                                    whileTap={{ scale: 0.98 }}
                                >
                                    <input ref={fileInputRef} type="file" accept="image/*" style={{ display: "none" }} onChange={handleFileChange} />
                                    <CloudUploadIcon sx={{ fontSize: 40, mb: 1, color: "#422afb" }} />
                                    <Typography variant="body2" align="center" color="#422afb" fontWeight="700" style={{ fontSize: "0.7rem" }}>
                                        Click to Upload
                                    </Typography>
                                    <Typography variant="caption" align="center" style={{ fontSize: "0.7rem", color: "#333" }} mt={0.5}>
                                        JPG, PNG up to 10MB
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                            background: "#422afb",
                            padding: { xs: "8px 16px", sm: "10px 20px" },
                            borderRadius: "10px",
                            fontSize: { xs: '11px', sm: '12px' },
                            '&:hover': {
                                backgroundColor: '#422afb',
                            },
                        }}
                        disabled={!Blog.Blogtitle || !Blog.description || !Blog.file}
                    >
                        Submit
                    </Button>
                </Box>
            </form>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Box>
    );
};

export default CreateBlog;

const webStyle = {
    UploadContainer: {
        backgroundColor: 'white', borderRadius: '12px', padding: '20px',
        boxShadow: '0 4px 20px rgba(66, 42, 251, 0.08)', border: '1px solid rgba(66, 42, 251, 0.12)',
        // [theme.breakpoints.up('sm')]: {
        //     padding: '20px',
        // },
    },
    UploadArea: {
        border: '2px dashed rgba(66, 42, 251, 0.2)', padding: '20px', borderRadius: '10px',
        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
        cursor: 'pointer', transition: 'all 0.3s ease', backgroundColor: 'rgba(66, 42, 251, 0.02)',
        // [theme.breakpoints.up('sm')]: {
        //     padding: '30px',
        // },
    },
    ImagePreview: {
        width: '100%', height: 230, borderRadius: '10px', overflow: 'hidden',
        marginBottom: '17px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        // [theme.breakpoints.up('sm')]: {
        //     height: 250,
        // },
    },
    StyledButton: {
        borderRadius: '10px', padding: '10px 20px', textTransform: 'none', fontWeight: 600,
        fontSize: '0.8rem', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', transition: 'all 0.2s ease',
        '&:hover': { boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)' },
        // [theme.breakpoints.up('sm')]: {
        //     padding: theme.spacing(0.8, 3),
        //     fontSize: '0.9rem',
        // },
    }
};

const inputStyle = {
    '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(66, 42, 251, 0.2)' },
    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(66, 42, 251, 0.5)' },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#422afb' },
    borderRadius: '12px', fontSize: '13px', backgroundColor: '#f9fafc', transition: 'all 0.3s ease',
    '&:hover': { backgroundColor: '#f0f2f8' },
};
