
import { ALL_USER_BOOKING_FAIL, ALL_USER_BOOKING_REQUEST, ALL_USER_BOOKING_SUCCESS, DELETE_USER_BOOKING_FAIL, DELETE_USER_BOOKING_REQUEST, DELETE_USER_BOOKING_RESET, DELETE_USER_BOOKING_SUCCESS, UPDATE_USER_BOOKING_FAIL, UPDATE_USER_BOOKING_REQUEST, UPDATE_USER_BOOKING_RESET, UPDATE_USER_BOOKING_SUCCESS } from "../constant/UserServiceBookingConstant";
  
  export const getUserServiceBookingReducer = (state = { getBookingData: [] }, action) => {
    switch (action.type) {
      case ALL_USER_BOOKING_REQUEST:
        return {
          loading: true,
          getBookingData: [],
        };
      case ALL_USER_BOOKING_SUCCESS:
        return {
          loading: false,
          getBookingData: action.payload,
        };
      case ALL_USER_BOOKING_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export const deleteUserServiceBookingReducer = (state = { DeletedService: {} }, action) => {
    switch (action.type) {
      case DELETE_USER_BOOKING_REQUEST:
        return {
          ...state,
          loading: true,
          isDeleted: false
        };
      case DELETE_USER_BOOKING_SUCCESS:
        return {
          ...state,
          loading: false,
          isDeleted: true
        };
      case DELETE_USER_BOOKING_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      case DELETE_USER_BOOKING_RESET:
        return {
          ...state,
          isDeleted: false
        };
      default:
        return state;
    }
  };
  
  export const updateUserServiceBookingReducer = (state = { updateUserBooking: {} }, action) => {
    switch (action.type) {
      case UPDATE_USER_BOOKING_REQUEST:
        return {
          ...state,
          loading: true,
          isAuthenticated: false
        };
      case UPDATE_USER_BOOKING_SUCCESS:
        return {
          ...state,
          loading: false,
          updateUserBooking: action.payload,
          isAuthenticated: true
        };
      case UPDATE_USER_BOOKING_RESET:
        return {
          ...state,
          loading: false,
          isAuthenticated: true
        };
      case UPDATE_USER_BOOKING_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isAuthenticated: false,
          updateUserBooking: null
        };
      default:
        return state;
    }
  };
  